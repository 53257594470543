import React, { CSSProperties } from "react"
import { Icon, IconProps } from "../../text/icon/icon"

type SimpleLineType = {
	iconProps?: IconProps
	className?: string
	style?: CSSProperties
	description: { text: string, className?: string}
	subDescription?: { text?: string, className?: string }
}

export const SimpleLine = ({
	iconProps,
	className = "",
	style,
	description = { text: "", className: "" },
	subDescription
}: SimpleLineType) => <div className={`flex flex-row  mb-3 ${className}`} style={style}>
	{iconProps && <Icon {...iconProps}/>}
	<span className={`mr-2 whitespace-nowrap ${description.className}`}>{description.text} :</span>
	{subDescription && <span className={subDescription.className}>{subDescription.text}</span>}
</div>

/*
<div className={`row ${customClass}`}>
	<div style={{ display: "none" }}>{subDescription?.text}</div>
	<div className="basis-full print:basis-full">
		<div className="flex flex-row items-end">
			{iconProps && <Icon {...iconProps} customClass="mr-1.5"/>}
			<span className={`bold ${description.customClass} mr-2.5`}>{description.text}</span>
			{subDescription && <div className={"col-5 col-media-4"}><span className={subDescription.customClass}>{subDescription.text}</span></div>}
		</div>
	</div>
</div>
*/