import { User, UserTypes } from "./authContext"

const apiUrl = process.env.REACT_APP_API_DOMAIN

export type checkAuthenticationResponse = {
	isAuthenticated: boolean
	isAdmin: boolean
	user?: User
	isBillingMonthly: boolean
}
export const checkAuthentication = async(jwtToken: string): Promise<checkAuthenticationResponse> => {
	try {
		const response = await fetch(`${apiUrl}/api/user/check-auth`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${jwtToken}`
			},
			method: "POST"
		})

		if(!response.ok) {
			throw new Error("Réponse réseau incorrecte")
		}

		const data = await response.json()
		let userType = UserTypes.TRANSPORTER
		if(data.isShipper) {
			userType = UserTypes.SHIPPER
		}
		if(data.isAdmin) {
			userType = UserTypes.ADMIN
		}

		return {
			isAuthenticated: data.isAuthenticated === true,
			isAdmin: data.isAdmin === true,
			isBillingMonthly: data.isBillingMonthly === true,
			user: {
				firstname: data.user.firstname,
				lastname: data.user.lastname,
				type: userType
			}
		}
	} catch (error) {
		console.error("Erreur lors de la vérification de l'authentification:", error)
		return {
			isAuthenticated: false,
			isAdmin: false,
			isBillingMonthly: false,
			user: undefined
		}
	}
}
