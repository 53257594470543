import React from "react"
import { useTranslation } from "react-i18next"

const NotFound = () => {
	const { t } = useTranslation("common")

	return <div>{t("not.found")}</div>
}

export default NotFound
