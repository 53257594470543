/* eslint-disable */
export type BrutElem = { [key: string]: string | BrutElem }[]
type FlatElem = string[][]

export const generateCSV = (data: FlatElem, title = "data"): void => {
	// Convert the object into CSV format
	const csvContent = data.reduce((acc, row) => {
		acc.push(row.join(';')) // Joindre les éléments de chaque ligne avec ';'
		return acc
	}, []).join('\n')

	const blob = new Blob([csvContent], { type: "text/csv" })
	// Create a link to download the file
	const a = document.createElement("a")
	a.href = URL.createObjectURL(blob)
	a.download = title + ".csv" // File name
	a.style.display = "none"
	document.body.appendChild(a)

	// Trigger the download
	a.click()

	// Clean up
	document.body.removeChild(a)
}

export const flattenBrutElem = (brutElem: BrutElem): FlatElem => {
	return [...flattenHeaderBrutElem(brutElem), ...flattenData(brutElem)]
}

export const flattenData = (brutElem: BrutElem): FlatElem => {
	const data: FlatElem = [] // Initialisation du tableau final
	console.log(JSON.stringify(brutElem))
	brutElem.map((currentValue) => {
		const row: string[] = []
		Object.keys(currentValue).forEach((key) => {
			const value = currentValue[key]
			if (value === null || value === undefined) {
				row.push("")
			} else if (typeof value === "object") {
				if (Array.isArray(value)) {
					const flattenedObject = flattenData(value as BrutElem)
					if (flattenedObject.length > 0) {
						row.push(...flattenedObject[0]) // Ajout des données aplaties
					} else {
						row.push("")
					}
				}
			} else {
				row.push(value as string)
			}
		})

		data.push(row)
	})

	return data
};

export const flattenHeaderBrutElem = (brutElem: BrutElem, parent = ""): FlatElem => {
	const headerFlat: FlatElem = []
	const rowFlat: string[] = []

	Object.keys(brutElem[0]).forEach((key: string) => {
		const Elemkey = key as keyof BrutElem[0]

		if(typeof brutElem[0][Elemkey] === 'object') {
			const test = flattenHeaderBrutElem(brutElem[0][Elemkey] as BrutElem, key)
			const result = test.reduce((accumulator, currentValue) => {
				accumulator.push(...currentValue) // Modify the accumulator
				return accumulator // Return the updated accumulator
			}, [])
			rowFlat.push(...result)
		} else {
			if(parent === "") {
				rowFlat.push(key)
			} else {
				rowFlat.push(parent + "." + key)
			}
		}
	})
	headerFlat.push(rowFlat)
	return headerFlat
}



export const flattenBrutElemOld = (brutElem: BrutElem) : FlatElem => {
	const keysSet = new Set<string>() // To collect all the unique keys (paths)
	const values: string[][] = [] // To collect the values in corresponding order

	// Recursive helper function to flatten each object and keep track of the path
	function flattenObject(obj: { [key: string]: string | BrutElem }, path: string[] = []) {
		for (const key in obj) {
			const value = obj[key]
			const currentPath = [...path, key]

			if (typeof value === 'string') {
				// Add the path to the keysSet
				keysSet.add(currentPath.join('.'))
				// Ensure values array is large enough
				const keyString = currentPath.join('.')
				if (values[keysSet.size - 1] === undefined) {
					values.push(new Array(keysSet.size - 1).fill('')) // Fill missing slots
				}
				values[values.length - 1].push(value) // Add value to the correct row
			} else if (Array.isArray(value)) {
				// If it's an array (BrutElem), recurse into the array
				value.forEach(item => flattenObject(item, currentPath))
			} else {
				// Otherwise, it's an object, so recurse into the object
				flattenObject(value, currentPath)
			}
		}
	}

	// Flatten each element in the BrutElem array
	brutElem.forEach(item => flattenObject(item))

	// Prepare final flatElem output with the keys in the first row
	const flatElem: FlatElem = [
		Array.from(keysSet), // First row is the unique paths
		...values // Subsequent rows are the corresponding values
	]

	return flatElem
}