import React from "react"
import { Icon, IconProps } from "../../text/icon/icon"
import { UseFormRegisterReturn } from "react-hook-form"

type InputProps = {
	register: UseFormRegisterReturn<any>
	style?: React.CSSProperties
	error?: boolean
	className?: string
	icon?: IconProps["type"]
	range: { min?: number, max?: number }
	onChange?: (value: number) => void
}

const InputNumber = ({ register, style, className, icon = undefined, error, range, onChange }: InputProps) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value)
		if(onChange) {
			onChange(value)
		}
	}

	return <div className="flex w-full bg-neutralLight radius-common">
		<input
			className={`${icon ? "pr-10" : "pr-2"} w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal radius-common py-2 focus:outline-none ${error ? "warningForm" : ""} ${className}`}
			type="number"
			{...register}
			min={range.min !== undefined ? range.min : undefined}
			max={range.max !== undefined ? range.max : undefined}
			onChange={handleChange}
			style={style}
		/>
		{icon && <Icon className="self-center mr-2" size="medium" type={icon}/>}
	</div>
}
export default InputNumber
