import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Slider from "../../../components/slider/Slider"
import { Button } from "../../../components/button/Button"
import "./offer.css"
import { Card } from "../../../components/layout/card/Card"
import { Trans, useTranslation } from "react-i18next"
import { Text } from "../../../components/text/Text"
import { formatStr } from "../../../i18n/formatStr"

const Offer = () => {
	const { t } = useTranslation("offer")
	const navigate = useNavigate()

	const goToCustom = (utilityKind: boolean) => {
		if(window.self !== window.top) {
			window.parent.postMessage({
				goToCustom: true,
				utilityKind
			}, process.env.REACT_APP_TRUST_DOMAIN as string)
		} else if(utilityKind) {
			navigate("/utility-custom")
		} else {
			navigate("/heavy-custom")
		}
	}
	const goToProvider = (utilityKind: boolean) => {
		if(window.self !== window.top) {
			window.parent.postMessage({
				goToProvider: true,
				utilityKind
			}, process.env.REACT_APP_TRUST_DOMAIN as string)
		} else {
			navigate(`/provision?category=${utilityKind ? "1" : "0"}`)
		}
	}

	const [inIframe, setInIframe] = useState(false)
	useEffect(() => {
		if(window.self !== window.top) {
			setInIframe(true)
		}
	}, [])

	return <Slider
		parentClassName={inIframe ? "" : "bg-third"}
		leftChildren={
			(alignment: string, mobile: boolean): React.ReactNode => <div className="left w-11/12 text-center h-64">
				<Card type="primary" className={`${inIframe ? "bg-white-opacity-80" : "bg-white"} `}>
					<div className={`min-h-52 pt-3 pl-3 pr-3 flex flex-col ${alignment !== "right" ? "space-around" : "justify-center"}`}>
						<div>
							<Text size="large" className={`offerText ${alignment !== "right" ? "viewFontNoSpace" : "show"}`}>{t("VL.title")}</Text>
							<Text size="large" className={`offerText ${alignment !== "right" ? "show" : "viewFontNoSpace"}`}>{t("VL.title", { context: "full" })}</Text>
							<div className={`pt-5 ${alignment !== "left" && !mobile ? "" : "hidden"}`}>
								<span>{formatStr(t("VL.description"))}</span>
							</div>
						</div>
						<div className={`flex justify-center ${alignment === "left" || mobile ? "" : "hidden"}`}>
							<Trans t={t} i18nKey="VL.description" context={"full"}/>
						</div>
						<div className={`flex justify-center space-around ${alignment === "left" || mobile ? "" : "hidden"}`}>
							<div className="w-5/12">
								<Button type="primary" className="offerText show" text={t("VL.offer.MAD")} onClick={() => goToProvider(true)}/>
							</div>
							<div className="w-5/12">
								<Button type="primary" className="offerText show" text={t("VL.offer.TSM")} onClick={() => goToCustom(true)}/>
							</div>
						</div>
					</div>
					<div className="h-4">
					</div>
				</Card>
			</div>
		}
		rightChildren={
			(alignment: string, mobile: boolean): React.ReactNode => <div className="right w-11/12 min-h-60 text-center h-64">
				<Card type="primary" className={`${inIframe ? "bg-white-opacity-80" : "bg-white"} `}>
					<div className={`min-h-52 pt-3 pl-3 pr-3 flex flex-col ${alignment !== "left" ? "space-around" : "justify-center"}`}>
						<div>
							<Text size="large" className={`offerText ${alignment !== "left" ? "show" : "viewFontNoSpace"}`}>{t("PL.title")}</Text>
							<Text size="large" className={`offerText ${alignment !== "left" ? "viewFontNoSpace" : "show"}`}>{t("PL.title", { context: "full" })}</Text>
							<div className={`pt-5 ${alignment !== "right" && !mobile ? "" : "hidden"}`}>
								<span>{formatStr(t("PL.description"))}</span>
							</div>
						</div>
						<div className={`flex justify-center ${alignment === "right" || mobile ? "" : "hidden"}`}>
							<Trans t={t} i18nKey="PL.description" context={"full"}/>
						</div>
						<div className={`flex justify-center space-around ${alignment === "right" || mobile ? "" : "hidden"}`}>
							<div className="w-5/12">
								<Button type="primary" className="offerText show" text={t("PL.offer.MAD")} onClick={() => goToProvider(false)}/>
							</div>
							<div className="w-5/12">
								<Button type="primary" className="offerText show" text={t("PL.offer.TSM")} onClick={() => goToCustom(false)}/>
							</div>
						</div>
					</div>
					<div className="h-4">
					</div>
				</Card>
			</div>}
	/>
}

export default Offer
