import React, { ReactNode } from "react"
import "./timeline.css"
import "../text/icon/icon.css"

type TimeLineType = {
	title?: string
	subtitle?: string
	children: ReactNode
}
// A export const Timeline = ({ children }: TimeLineType) => <ol className="timeline">

export const Timeline = ({ children }: TimeLineType) => <ol className="timeline">
	{children}
</ol>