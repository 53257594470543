/* eslint-disable */
import React, { useEffect, useState } from "react"
import Paging from "./content/Paging"
import Row from "./content/Row"
import Header from "./content/Header"
import Filter from "./content/Filter"
import { flattenBrutElem, generateCSV } from "../../helper/csv"
import Loading from "../Modal/Loading"

// eslint-disable-next-line no-shadow
export enum FilterStatusEnum {
	INACTIVE,
	ASCENDING,
	DESCENDING
}

type RowData<T> = { [K in keyof T]: any }

export type TableProps<T> = {
	data: RowData<T>[]
	header: {
		[K in keyof T]?: {
			displayMax?: "sm" | "md" | "lg"
			label: string
			filter?: {
				status: FilterStatusEnum
				setStatus: (s: FilterStatusEnum) => void
			}
		}
	}
	headerParam?: {
		search?: {
			fullText?: boolean
		},
		extract?: boolean,
		paging?: { // Filtre le nombre de ligne
			nbLine: number
		}
		rowAction: { // Action pour chaque ligne
			title?: string
			onclick: (e: T) => void,
			display: (e: T, b: boolean) => React.ReactNode
		}
	}
}

const Table = <T, >({ data, header, headerParam }: TableProps<T>) => {
	const [currentPage, setCurrentPage] = useState(0)
	const [totalPages, setTotalPages] = useState(Math.ceil(data.length / (headerParam?.paging?.nbLine ?? 1)))
	const [textSearch, setTextSearch] = useState<string>("")

	// Update total pages whenever data, nbLine, or text search changes
	useEffect(() => {
		const nbLine = headerParam?.paging?.nbLine ?? 1
		const filteredData = data.filter((row) => Object.keys(header).some((key) => {
			const value = row[key as keyof T]
			return value && (value.toString().toLowerCase()).
				includes(textSearch.toLowerCase())
		}))
		setTotalPages(Math.ceil(filteredData.length / nbLine))
	}, [data, headerParam?.paging?.nbLine, textSearch])

	const handleFilterData = (d: RowData<T>[], nbLine?: number) => {
		// Étape 1 : Filtrer les données par recherche texte
		const dataFilter = d.filter((row) => Object.keys(header).some((key) => {
			const value = row[key as keyof T]
			return value && value.toString().toLowerCase().
				includes(textSearch.toLowerCase())
		}))

		// Étape 2 : Trier les données
		let dataSort = dataFilter.sort((rowA, rowB) => (Object.keys(header) as Array<keyof T>).reduce((res, curr) => {
			const filterStatus = header[curr]?.filter?.status;

			if (!filterStatus) {
				return res;
			}
			switch(filterStatus) {
				case FilterStatusEnum.ASCENDING:
					// eslint-disable-next-line no-nested-ternary
					return res || (rowA[curr] > rowB[curr] ? 1 : rowA[curr] < rowB[curr] ? -1 : 0)
				case FilterStatusEnum.DESCENDING:
					// eslint-disable-next-line no-nested-ternary
					return res || (rowA[curr] < rowB[curr] ? 1 : rowA[curr] > rowB[curr] ? -1 : 0)
				default:
					return res
			}
		}, 0))

		// Étape 3 : Appliquer la pagination
		if(nbLine) {
			dataSort = dataSort.slice(currentPage * nbLine, (currentPage + 1) * nbLine)
		}

		return dataSort
	}
	const extractCSV = () => {
		generateCSV(flattenBrutElem(handleFilterData(data)), "commandes")
	}

	return <div>
		{headerParam?.search && <Filter headerParam={headerParam} textSearch={textSearch} setTextSearch={setTextSearch} extractCSV={extractCSV}/>}
		<div className="grid">
			<Header header={header} headerParam={headerParam} setCurrentPage={setCurrentPage}/>
			<Row data={handleFilterData(data, headerParam?.paging?.nbLine)} headerParam={headerParam} header={header}/>
			{headerParam && headerParam.paging && <Paging currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>}
		</div>
	</div>
}

export default Table
