import React, { ReactNode } from "react"
import { Icon } from "../../text/icon/icon"

type IncientStepType = {
	className?: string
	description: string
	subDescription?: string
	children?: ReactNode
}

export const IncidentStep = ({ className, description, subDescription, children }: IncientStepType) => <li className="timeline-item">
	<span className="timeline-item-icon">
		<Icon  type="warning" className="icon-warning p-1" size="medium" />
	</span>
	<div className="timeline-item-wrapper">
		<div className={className}>
			<div className="timeline-item-description mb-3"><span className="medium incident-background rounded-lg p-2">{description}</span></div>
			{subDescription && <div className="timeline-item-subdescription"><span>{subDescription}</span></div>}
		</div>
		{children}
	</div>
</li>
