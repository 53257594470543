import React, { useEffect, useState } from "react"
import moment from "moment/moment"
import { useTranslation } from "react-i18next"
import { Icon } from "../../../text/icon/icon"
import { UseFormRegisterReturn } from "react-hook-form"

type InputProps = {
	style?: React.CSSProperties
	register?: UseFormRegisterReturn<any>
	className?: string
	error?: boolean
	onClick: () => void
	format?: string
	icon?: "calendar" | "clock"
	placeholder?: string
	date: Date | moment.Moment | undefined
}

export const InputDate = ({ register, style, className, onClick, format = "ll", icon = "calendar", date, placeholder, error }: InputProps) => {
	const { t, i18n } = useTranslation()
	const [fomattedDate, setFormattedDate] = useState<string>("")

	useEffect(() => {
		if(date) {
			let momentFormat = "ll"
			if(format) {
				momentFormat = format
			}
			setFormattedDate(moment(date).locale(i18n.language).format(momentFormat))
		}
	}, [date])

	return <div className={`flex w-full bg-neutralLight radius-common ${error ? "warningForm" : ""}`}>
		<input
			{...register}
			className={`w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal radius-common py-2 pr-2 focus:outline-none ${className}`}
			type="text"
			placeholder={placeholder ? t(placeholder) : ""}
			style={style}
			onClick={onClick}
			value={t("{{date}}", { date: fomattedDate })}
			readOnly
		/>
		<Icon className="self-center mr-1" size="medium" type={ icon}/>
	</div>
}
