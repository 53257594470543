import React from "react"
import { Icon } from "../../text/icon/icon"

type ListLineProps = {
	type: number // 1 chargement - 2 déchargement
	lots: {
		number: number
		description?: string,
	}[] | undefined
}

export const ListLine = ({
	type,
	lots
}: ListLineProps) => <div>
	<div className="flex flex-row items-end ml-5 mb-1.5">
		<Icon type={type === 1 ? "loading" : "unloading"} size="small" className="mr-2.5"/><span className="text-sm">{(() => {
			let displayType = "Déchargement"
			if(type === 1) {
				displayType = "Chargement"
			}
			if(lots && lots.length > 0) {
				displayType += "(s) :"
			}
			return displayType
		})()}</span>
	</div>
	{lots && lots.map(
		lot => <div key={lot.number} className="ml-9 mb-0.5"><span className="text-sm">lot {lot.number} {lot.description ? `: ${lot.description}` : "" }</span></div>
	)}
</div>
