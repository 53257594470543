import React from "react"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import moment from "moment"
import { Icon } from "../../text/icon/icon"
import { UseFormRegisterReturn } from "react-hook-form"

type TimePickerProps = {
	time: moment.Moment | undefined
	placeholder?: string
	error?: boolean
	register?: UseFormRegisterReturn<any>
	defaultHour?: moment.Moment
	setTime: (time: moment.Moment | undefined) => void
}

// https://www.npmjs.com/package/react-datetime
export const TimePicker = ({ time, setTime, placeholder, error, register, defaultHour }: TimePickerProps) => <div className="w-full text-base font-normal">
	<Datetime
		value={time}
		onChange={(value) => setTime(value as moment.Moment)}
		dateFormat={false}
		timeFormat={"HH:mm"}
		inputProps={{ placeholder: "HH:mm" }}
		renderView={(viewMode, renderCalendar) => <div className="border-2 radius-common border-c-primary">{renderCalendar()}</div>}
		renderInput={(props, openCalendar) => <div className={`flex w-full bg-neutralLight radius-common ${error ? "warningForm" : ""}`}>
			<input
				{...props}
				{...register}
				placeholder={placeholder}
				className={"w-full pl-5 text-c-neutral bg-neutralLight border-c-neutral text-base font-normal radius-common py-2 pr-2 focus:outline-none"}
				onFocus={() => {
					openCalendar()
					if(!time) {
						setTime(defaultHour)
					}
				}}
				readOnly
			/>
			<Icon className="self-center mr-2" size="medium" type="clock"/>
		</div>
		}
		timeConstraints={{
			hours: { step: 1, min: 6, max: 16 },
			minutes: { min: 0, max: 59, step: 15 }
		}}
	/>
</div>

export default TimePicker
