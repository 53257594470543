import React from "react"
import { Icon } from "../text/icon/icon"
import "./bufferedImage.css"

type BufferedImageType = {
	src: string,
	signature?: string
}

export const BufferedImage = ({ src, signature }: BufferedImageType) => <div className="row">
	<div style={{ position: "relative" }}>
		<img  className="bufferedImageSize" src={src}/>
		{signature && <div className="row v-center bufferedImageDiv" >
			<Icon type="signature" size="xtr-small" color="valid" className="media-icon-xtr-small m-1"/>
			<span className="small" style={{ paddingRight: 3 }}>signé par {signature}</span>
		</div>}
	</div>
</div>