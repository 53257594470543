import React, { CSSProperties } from "react"
import { UseFormRegisterReturn } from "react-hook-form"

type TextProps = {
	register: UseFormRegisterReturn<any>
	className?: string
	error?: boolean
	placeholder?: string
	disabled?: boolean
	onClick?: () => void
	readonly?: boolean
	style?: CSSProperties
	type?: "text" | "password"
}


export const Text = ({ register, error, placeholder, className = "", disabled = false, readonly = false, style, onClick, type = "text" }: TextProps) => <div className="flex w-full bg-neutralLight radius-common" onClick={onClick}>
	<input
		style={style}
		readOnly={readonly}
		disabled={disabled}
		className={`w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal radius-common py-2 focus:outline-none ${error ? "warningForm" : ""} ${className}`}
		{...register}
		placeholder={placeholder}
		type={type}
	/>
</div>
