import React, { useEffect, useRef } from "react"
import Here from "@here/maps-api-for-javascript"
import {
	RouteType,
	calculateCenter,
	calculateRoute,
	calculateBoundsWithPadding
} from "../../helper/here"

type HereMapV2Type = {
	positions: RouteType[],
	reset?: {
		val: boolean,
		askReset: (val: boolean) => void
	}
}

const HereMap = ({ positions, reset }: HereMapV2Type) => {
	const mapRef = useRef<any>(null)
	const platform = useRef<any>(null)
	const map = useRef<any>(null)
	const apiKey = "P-vntSgzpgTRxTY7Qvhmodfee9s7FHhY4wAlX6tvIJQ"

	useEffect(() => {
		if(map.current) {
			if(reset?.val) {
				map.current.getViewModel().setLookAtData({
					bounds: calculateBoundsWithPadding(positions, 0.1)
				})
				reset.askReset(false)
			} else {
				setTimeout(() => {
					map.current.getViewModel().setLookAtData({
						bounds: calculateBoundsWithPadding(positions, 0.03)
					})
				}, 500)
			}
		}
	}, [ reset?.val ])

	useEffect(
		() => {
			if(!map.current) {
				platform.current = new Here.service.Platform({
					apikey: apiKey
				})
				// Create a new Raster Tile service instance
				const rasterTileService = platform.current.getRasterTileService({
					queryParams: {
						style: "explore.day",
						size: 512
					}
				})

				const rasterTileProvider = new Here.service.rasterTile.Provider(
					rasterTileService
				)
				// Create a new Tile layer with the Raster Tile provider
				const rasterTileLayer = new Here.map.layer.TileLayer(rasterTileProvider)
				// Create a new map instance with the Tile layer, center and zoom level
				const newMap = new Here.Map(mapRef.current, rasterTileLayer, {
					pixelRatio: window.devicePixelRatio,
					// Todo voir pour center et ajuster le zoom de la carte en fonction des points
					center: calculateCenter(positions)
				})

				newMap.getViewModel().setLookAtData({
					bounds: calculateBoundsWithPadding(positions, 0.03)
				})

				// Add panning and zooming behavior to the map
				const behavior = new Here.mapevents.Behavior(
					new Here.mapevents.MapEvents(newMap)
				)
				behavior.enable()

				// Set the map object to the reference
				map.current = newMap

				calculateRoute(
					platform.current,
					map.current,
					positions
				)
			} else {
				calculateRoute(
					platform.current,
					map.current,
					positions,
					true
				)
			}
		},
		[map, positions]
	)
	// Return a div element to hold the map
	return <div style={ { width: "100%", height: "100%", minHeight: 350 } } ref={mapRef} />
}

export default HereMap
