import { Text, TextProps } from "../text/Text"
import React from "react"
import { Icon } from "../text/icon/icon"

type FormInputProps = {
	titleSize?: TextProps["size"]
	inputText?: string
	trashOnClick?: () => void
	className?: string
	childClassName?: string
	error?: boolean
	errorText?: string
	children: React.ReactNode
}
const FormInput = ({ children, className, error, errorText, inputText, childClassName, titleSize = "base", trashOnClick }: FormInputProps) => <div className={className}>
	<div className="flex w-full">
		{trashOnClick && <span className="mr-5" onClick={trashOnClick}><Icon type="trash" size="small"/></span>}
		{inputText && <Text className="text-c-neutral" size={titleSize}>{inputText}</Text>}
	</div>
	<div className={`mt-2 ${childClassName}`}>
		{children}
	</div>
	{ error && errorText && <div className="flex w-full">
		<Text className="text-c-warning pl-1">{errorText}</Text>
	</div>}
</div>

export default FormInput
