import React from "react"

export const UsefulWidthIcon = (props: React.SVGProps<SVGSVGElement>) => <svg style={props.style} transform={props.transform} className={props.className} version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 50 50" xmlSpace="preserve">
	<g>
		<path d="M23.7,16.9c0,4.4,0,22.1,0,26.5c-4,0-8,0-12.1,0c0-2.2,0-17.7,0-19.9c-0.2,0-0.4,0-0.6,0c-0.3,0-0.5,0.3-0.5,0.6
						c0,1.2,0,2.5,0,3.7c0,0.8-0.8,1.4-1.5,1.1c-0.4-0.2-0.7-0.5-0.7-1c0-1.4,0-2.7,0-4.1c0-1.4,1.2-2.5,2.6-2.6c0.2,0,0.4,0,0.7,0
						c0-0.3,0-0.6,0-0.9c0-2.1,1.4-3.5,3.5-3.5c2.8,0,5.5,0,8.3,0C23.4,16.9,23.5,16.9,23.7,16.9z"/>
		<path d="M19.2,43.4c0-4.4,0-22.1,0-26.5c0.1,0,6.9,0,7,0c2.7,0,5.5,0,8.2,0c2.1,0,3.5,1.4,3.5,3.5c0,0.3,0,0.6,0,0.9
						c0.2,0,0.4,0,0.6,0c1.5,0,2.7,1.2,2.7,2.7c0,1.3,0,2.5,0,3.8c0,0.7-0.5,1.2-1.1,1.2c-0.6,0-1.1-0.5-1.1-1.2c0-1.1,0-2.3,0-3.4
						c0-0.9-0.1-1-1.1-1c0,2.2,0,17.7,0,19.9C34,43.4,23.3,43.4,19.2,43.4z"/>
		<path d="M39.1,42.8c0,0.4,0,0.7,0,1c0.1,0,0.3,0.1,0.4,0.1c0.5,0.2,0.8,0.7,0.7,1.2c-0.1,0.5-0.5,0.9-1,1c-0.1,0-0.2,0-0.3,0
						c-9.4,0-18.7,0-28.1,0c-0.2,0-0.3,0-0.5,0c-0.5-0.1-0.9-0.5-0.9-1c0-0.5,0.3-1,0.7-1.1c0.1,0,0.2-0.1,0.3-0.1c0-0.3,0-0.7,0-1
						C20,42.8,29.5,42.8,39.1,42.8z"/>
		<path d="M12.7,45.6c2.6,0,5.1,0,7.7,0c0,0.1,0,0.1,0,0.2c0,0.7,0,1.3,0,2c0,1.2-1,2.2-2.2,2.2c-1.1,0-2.2,0-3.4,0
						c-1.2,0-2.1-0.9-2.2-2.1C12.6,47.2,12.7,46.4,12.7,45.6z"/>
		<path d="M36.9,45.6c0,0.8,0,1.6,0,2.4c-0.1,1.1-1,2-2.1,2c-1.1,0-2.3,0-3.4,0c-1.2,0-2.1-1-2.2-2.2c0-0.7,0-1.5,0-2.2
						C31.8,45.6,34.3,45.6,36.9,45.6z"/>
	</g>
	<line className="cls-1" x1="15" y1="31.2" x2="33.6" y2="31.2" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.83px"/>
	<path fill="#FFF" d="M13,31.2c1.2,0.5,2.7,1.2,3.6,2l-0.8-2l0.8-2C15.7,29.9,14.2,30.8,13,31.2z"/>
	<path fill="#FFF" d="M36.5,31.2c-1.2,0.5-2.7,1.2-3.7,2l0.8-2l-0.8-2C33.8,29.9,35.3,30.8,36.5,31.2z"/>
</svg>
