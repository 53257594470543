import React from "react"

export const UsefulHeightIcon = (props: React.SVGProps<SVGSVGElement>) => <svg style={props.style} transform={props.transform} className={props.className} version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 50 50" xmlSpace="preserve">
	<path d="M50,46c-0.9,0-1.9,0-2.8,0c-0.1,0-0.3,0.2-0.3,0.4c-0.6,1.9-1.9,3.1-3.8,3.6c-2.4,0.6-5.2-0.9-5.9-3.3
					c-0.1-0.5-0.3-0.6-0.8-0.6c-4.4,0-8.9,0-13.3,0c-0.4,0-0.5,0.1-0.6,0.5c-0.6,2-2,3.2-4,3.5c-2,0.3-3.7-0.4-4.9-2
					c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6,0.8-1.3,1.5-2.2,1.9c-2.7,1.3-6.1-0.2-7-3.1c-0.1-0.4-0.2-0.5-0.7-0.5c-1,0-2,0-3,0
					c-0.2,0-0.3,0-0.5,0c0-1,0-2,0-3c0.4,0,0.8,0,1.3,0c0-0.2,0-0.4,0-0.7c0-7.6,0-15.3,0-22.9c0-0.3,0-0.6,0.1-0.9
					C1.5,17.6,2.2,17,3,16.9c0.2,0,0.4,0,0.6,0c9.1,0,18.2,0,27.3,0c0.3,0,0.6,0,0.9,0.1c0.8,0.2,1.4,0.9,1.5,1.7c0,0.2,0,0.4,0,0.6
					c0,7.6,0,15.2,0,22.9c0,0.2,0,0.4,0,0.6c0.6,0,1.1,0,1.7,0c0-0.2,0-0.4,0-0.6c0-4.6,0-9.2,0-13.7c0-1,0.2-1.2,1.2-1.2
					c2.1,0,4.1,0,6.2,0c0.4,0,0.8,0.1,1.1,0.4c1.7,1.6,3.4,3.2,5,4.8c0.3,0.3,0.5,0.7,0.5,1.1c0,2.9,0,5.8,0,8.6c0,0.2,0,0.4,0,0.6
					c0.4,0,0.7,0,1,0C50,43.9,50,44.9,50,46z M45.6,35.5c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.7-0.4-0.9c-0.8-0.7-1.6-1.4-2.4-2.2
					c-0.6-0.6-1.2-0.9-2-0.8c-1,0.1-1.9,0-2.9,0c0,1.8,0,3.5,0,5.2C40.4,35.5,43,35.5,45.6,35.5z M20.1,44.9c0-1.3-1.1-2.5-2.5-2.5
					c-1.3,0-2.5,1.2-2.5,2.5c0,1.3,1.1,2.5,2.5,2.5C19,47.4,20.1,46.3,20.1,44.9z M44.5,44.9c0-1.4-1.1-2.5-2.4-2.5
					c-1.4,0-2.5,1.1-2.5,2.5c0,1.3,1.1,2.5,2.4,2.5C43.3,47.4,44.5,46.3,44.5,44.9z M11.4,44.9c0-1.3-1.1-2.5-2.5-2.5
					c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5C10.3,47.4,11.4,46.3,11.4,44.9z"/>
	<line stroke="#fff" x1="18.4" y1="36.2" x2="18.4" y2="21.9"/>
	<polygon fill="#fff" points="18.4,40 20.8,34.6 18.4,35.9 16.1,34.6 "/>
	<path fill="#fff" d="M18.4,18.6c0.6,1.5,1.6,3.4,2.7,4.6l-2.7-1l-2.7,1C16.8,22,17.9,20.1,18.4,18.6z"/>
</svg>
