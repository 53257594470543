/* eslint-disable */
import * as React from 'react';
import {Range, getTrackBackground, Direction} from "react-range";
import {FlipIcon, Icon} from "../../../text/icon/icon";
import {Text} from "../../../text/Text";
import {Tooltip} from "../../../toolTip/Tooltip";
import {useEffect} from "react";

export type SimpleRangeProps = {
	step?: number
	lowerBound?: number
	upperBound?: number
	value?: number
	showBound?: boolean
	direction: "left" | "right"
	onChange:(value: number) => void
}

const SimpleRange = ({ step = 1, lowerBound = 0, upperBound = 100, value, onChange, direction, showBound = true }: SimpleRangeProps) => {
	const [values, setValues] = React.useState([lowerBound]);
	useEffect(() => {
		if(!value) {
			setValues([lowerBound])
		} else {
			setValues([value]);
		}
	}, [value])
	let directionApply: Direction
	let flipIcon: FlipIcon
	switch(direction) {
		case "left":
			directionApply = Direction.Left;
			flipIcon = FlipIcon.horizontal
			break
		case "right":
		default:
			directionApply = Direction.Right;
			flipIcon = FlipIcon.none
	}

	return <Range
			direction={directionApply}
			values={values}
			step={step}
			min={lowerBound}
			max={upperBound}
			rtl={false}
			onChange={(values) => {
				onChange(values[0])
				setValues(values)
			}}
			renderTrack={({ props, children }) => (
				<div className="grow flex h-9 w-full justify-between"
					onMouseDown={props.onMouseDown}
					onTouchStart={props.onTouchStart}
					style={{
						...props.style,
					}}
				>
					{showBound && <Text className="w-fit self-center pr-2">{`${lowerBound}	`}</Text>}
					<div className="w-4/6 h-1.5 rounded self-center"
						ref={props.ref}
						style={{
							background: getTrackBackground({
								values,
								colors: ['var(--neutralLight)', 'var(--primary)'],
								min: lowerBound,
								max: upperBound,
								direction: directionApply,
								rtl: false
							}),
						}}
					>
						{children}
					</div>
					{showBound && <Text className="w-fit self-center">{`${upperBound}`}</Text>}
				</div>
			)}
			renderThumb={({props, value, isDragged}) => {
				let { key, ...rest } = props;
				return <div className="flex h-7 w-7 rounded-full bg-white justify-center items-center"
					key={key}
					{...rest}
					style={{...rest.style, boxShadow: '0px 2px 6px #AAA'}}
				>
					<Tooltip content={value.toString()}>
						<Icon flip={flipIcon} type="play" size="small" color={isDragged ? "neutralLight" : "primary"}/>
					</Tooltip>
				</div>
			}}
	/>
};

export default SimpleRange;