import { FC, SVGProps } from "react"
import { ArrowLeftIcon } from "./arrowLeft"
import { CalendarIcon } from "./calendar"
import { CalendarDateTimeIcon } from "./calendarDateTime"
import { CheckIcon } from "./check"
import { CheckLineIcon } from "./checkLine"
import { ClockIcon } from "./clock"
import { CraneCapacityIcon } from "./craneCapacity"
import { CrossIcon } from "./cross"
import { DotIcon } from "./dot"
import { DropdownIcon } from "./dropdown"
import { EnvelopeIcon } from "./envelope"
import { FacebookIcon } from "./facebook"
import { HouseIcon } from "./house"
import { InstagramIcon } from "./instagram"
import { LeafIcon } from "./leaf"
import { LinkedinIcon } from "./linkedin"
import { LoadingIcon } from "./loading"
import { LocationIcon } from "./location"
import { LocationRoundedIcon } from "./locationRounded"
import { OperationIcon } from "./operation"
import { ParkedIcon } from "./parked"
import { PhoneIcon } from "./phone"
import { PlayIcon } from "./play"
import { QuestionMarkIcon } from "./questionMark"
import { SignatureIcon } from "./signature"
import { SortArrowIcon } from "./sortArrow"
import { StarIcon } from "./star"
import { TrashIcon } from "./trash"
import { UnloadingIcon } from "./unloading"
import { UploadIcon } from "./upload"
import { UsefulHeightIcon } from "./usefulHeight"
import { UsefulLengthIcon } from "./usefulLength"
import { UsefulWeightIcon } from "./usefulWeight"
import { UsefulWidthIcon } from "./usefulWidth"
import { WarningIcon } from "./warning"
import { DropdownArrowIcon } from "./dropdownArrow"

const Icons: Record<string, FC<SVGProps<SVGSVGElement>>> = {
	"arrow-left": ArrowLeftIcon,
	"calendar": CalendarIcon,
	"calendar-date-time": CalendarDateTimeIcon,
	"check": CheckIcon,
	"check-line": CheckLineIcon,
	"clock": ClockIcon,
	"crane-capacity": CraneCapacityIcon,
	"cross": CrossIcon,
	"dot": DotIcon,
	"dropdown": DropdownIcon,
	"dropdown-arrow": DropdownArrowIcon,
	"envelope": EnvelopeIcon,
	"facebook": FacebookIcon,
	"house": HouseIcon,
	"instagram": InstagramIcon,
	"leaf": LeafIcon,
	"linkedin": LinkedinIcon,
	"loading": LoadingIcon,
	"location": LocationIcon,
	"location-rounded": LocationRoundedIcon,
	"operation": OperationIcon,
	"parked": ParkedIcon,
	"phone": PhoneIcon,
	"play": PlayIcon,
	"question-mark": QuestionMarkIcon,
	"signature": SignatureIcon,
	"sort-arrow": SortArrowIcon,
	"star": StarIcon,
	"trash": TrashIcon,
	"unloading": UnloadingIcon,
	"upload": UploadIcon,
	"useful-height": UsefulHeightIcon,
	"useful-length": UsefulLengthIcon,
	"useful-weight": UsefulWeightIcon,
	"useful-width": UsefulWidthIcon,
	"warning": WarningIcon
}

export default Icons
