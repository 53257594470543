import React, { ReactNode } from "react"
import "./V2Tooltip.css"

type TooltipProps = {
	content: string,
	children: ReactNode,
	orientation?: "top" | "left" | "right" | "bottom",
}
export const Tooltip = ({ content, children, orientation = "top" }: TooltipProps) => <div className="mainTooltip">
	<div className="parentTooltip">
		<div
			className="contentTooltip"
			data-tooltip={content}
			data-tooltip-location={orientation} // Top | Left | Right | Bottom
		>
			{children}
		</div>
	</div>
</div>
