
export const CompatibilityCallForBidV1 = {
	1: "|PROPERTY_DOMAIN_TRANSPORT_OF_MATERIALS||PROPERTY_BODY8|",
	2: "|PROPERTY_DOMAIN_TRANSPORT_OF_MATERIALS||PROPERTY_BODY_BENNE_GRUE|",
	3: "|PROPERTY_DOMAIN_TRANSPORT_OF_MATERIALS||PROPERTY_BODY10|",
	5: "|PROPERTY_DOMAIN_TRANSPORT_OF_MATERIALS||PROPERTY_BODY_PLATEAU_GRUE|",
	6: "|PROPERTY_DOMAIN_TRANSPORT_OF_MATERIALS||PROPERTY_BODY_MAT_BRAS_AMP_GRUE|",
	4: "|PROPERTY_DOMAIN_TRANSPORT_OF_MATERIALS||PROPERTY_BODY_MAT_TRACT_GRUE_PLAT_CIT|",
	7: "|PROPERTY_DOMAIN_UTILITAIRE||PROPERTY_BODY_UT_BENNE|",
	8: "|PROPERTY_DOMAIN_UTILITAIRE||PROPERTY_BODY_UT_TOLE|",
	9: "|PROPERTY_DOMAIN_UTILITAIRE||PROPERTY_BODY_UT_CAISSE_GRAND_VOLUME|"
}
