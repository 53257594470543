/* eslint-disable */
import React, { useRef, useState } from "react"
import { Icon } from "../../text/icon/icon"
import { useTranslation } from "react-i18next"
import SelectMenu from "./content/SelectMenu";

type SelectProps = {
	selectedValue: string[],
	setValue: (value: string) => void,
	canBeNull?: boolean
	multiple?: boolean
	className?: string
	values: string[]

}
const Select = ({ selectedValue, setValue, values, className, canBeNull = true, multiple = false }: SelectProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const inputRef = useRef<HTMLDivElement | null>(null)
	const handleSelect = (newValue: string) => {
		setValue(newValue)
		setIsOpen(multiple || false)
	}

	const { t } = useTranslation("common")
	return <div ref={inputRef} className="w-full">
		<div className="relative mt-2">
			<button type="button"
        className={`${className} min-h-12 location  pl-3 p-2 pr-5 bg-neutralLight radius-common flex flex-wrap w-full mt-2`}
				aria-haspopup="listbox" aria-expanded={isOpen} aria-labelledby="listbox-label"
				onClick={() => setIsOpen(!isOpen)}>
				{selectedValue.map((item: any, index) => {
					if(item === "") return <div key={index}></div>
					return <div key={index} className="radius-common pl-3 pr-2 py-1 bg-primary mr-2 flex items-center cursor-pointer">
						<span>{item}</span>
						{multiple && <div className="bg-primaryDark text-c-primary items-end ml-1 w-5 h-5 radius-common flex justify-center"
									onClick={() => handleSelect(item)}>x</div>}
					</div>
				})}
				<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
					<Icon type="dropdown" className="self-center mr-2" color="black" size="medium"/>
				</span>
			</button>
			<SelectMenu
				canBeNull={canBeNull}
				inputRef={inputRef}
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				values={values}
				currentValue={selectedValue}
				onChange={handleSelect}
				showSelectedItem={true}
			/>
		</div>
	</div>
}

export default Select
