/* eslint-disable */
import React from "react"
import RangeSlider from "./range/Range"
import Select from "./select/Select"
import CheckBox from "./checkbox/CheckBox";
import Switch from "./switch/Switch";
import {useTranslation} from "react-i18next";

type InputHelperNumberProps = {
	type: "number"
	value?: number
	setValue: (value: number) => void
	lowerBound?: number
	upperBound?: number
}

type InputHelperBooleanProps = {
	type: "boolean"
	value?: boolean
	setValue: (value: boolean) => void
}

type InputChoiceHelperProps = {
	type: "choice"
	choices: string[]
}

type  InputHelperProps = any

export const InputHelper = (props: InputHelperProps) => {
	const { t } = useTranslation("common")
	switch(props.type) {
		case "number":
			props as InputHelperNumberProps
			return <RangeSlider type="multiple" values={props.value} onChange={props.setValue} lowerBound={props.rules?.attr?.min} upperBound={props.rules?.attr?.max}/>
		case "boolean":
			props as InputHelperBooleanProps
			return <Switch buttonState={props.value} setButtonState={props.setValue} leftText={t("input.switch.true")} rightText={t("input.switch.false")}/>
		case "choice":
			props as InputChoiceHelperProps
			return <Select selectedValue={props.value} setValue={props.setValue} values={props.rules.choices} multiple={true}/>
		default:
			return <div></div>
	}
}

