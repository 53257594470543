/* eslint-disable */
import * as React from 'react';
import {Range, getTrackBackground, Direction} from "react-range";
import {FlipIcon, Icon} from "../../../text/icon/icon";
import {Text} from "../../../text/Text";
import {Tooltip} from "../../../toolTip/Tooltip";

export type MultipleRangeProps = {
	step?: number
	lowerBound?: number
	upperBound?: number
	values: number[]
	innerColor?: string[]
	showBound?: boolean
	onChange:(values: number[]) => void
}

const generateInnerColor = (nbBound: number, innerColor?: string[]): string[] => {
	if(innerColor) {
		return innerColor
	}
	const tableau = []
	for (let i = 0; i <= nbBound; i++) {
		if(i === 0 || i === nbBound) {
			tableau.push('var(--neutralLight)')
		} else if(i % 2 === 0) {
			tableau.push('var(--primaryDark)')
		} else {
			tableau.push('var(--primary)')
		}
	}
	return tableau
}

const MultipleRange = ({
	step = 1,
	lowerBound = 0,
	upperBound = 100,
	values,
 	onChange,
	innerColor,
 showBound = true
}: MultipleRangeProps) => <Range
	values={values}
	step={step}
	min={lowerBound}
	max={upperBound}
	rtl={false}
	onChange={(values) => {
		onChange(values)
	}}
	renderTrack={({ props, children }) => (
		<div className="grow flex h-9 w-full justify-between"
			onMouseDown={props.onMouseDown}
			onTouchStart={props.onTouchStart}
			style={{
				...props.style,
			}}
		>
			{showBound && <Text className="absolute bottom-6 -left-1">{`${lowerBound}	`}</Text>}
			<div className="w-full h-1.5 mt-2 rounded self-center"
				ref={props.ref}
				style={{
					background: getTrackBackground({
						values,
						colors: generateInnerColor(values.length, innerColor),
						min: lowerBound,
						max: upperBound,
						rtl: false
					}),
				}}
			>
				{children}
			</div>
			{showBound && <Text className="absolute bottom-6 -right-1">{`${upperBound}`}</Text>}
		</div>
	)}
	renderThumb={({props, value, index, isDragged}) => {
		const { key, ...rest } = props;
		return <div className="flex h-7 w-7 border-c-third border-2 rounded-full bg-white justify-center items-center"
			 key={key}
			 {...rest}
			 style={{...props.style, boxShadow: '0px 2px 6px #AAA'}}
		>
			<Tooltip content={value.toString()}>
				{value !== lowerBound && value !== upperBound && <Text className="text-c-neutral absolute bottom-6 left-1" style={{ bottom: 20 }}>{value.toString()}</Text>}
				<Icon flip={index + 1 === values.length ? FlipIcon.horizontal : FlipIcon.none} type="dot" color="white"
					size="small"/>
			</Tooltip>
		</div>
	}}
/>

export default MultipleRange;