import React, { ReactNode } from "react"
import { Icon, IconProps } from "../../text/icon/icon"

type MainStepType = {
	itemIcon?: IconProps
	itemText?: string
	className?: string
	description: string
	subDescription?: string
	children?: ReactNode
}

export const MainStep = ({ itemIcon, itemText, className, description, subDescription, children }: MainStepType) => <li className="timeline-item">
	<span className="timeline-item-icon">
		{itemIcon && <Icon {...itemIcon}/>}
		{	itemText && itemText}
	</span>
	<div className="timeline-item-wrapper">
		<div className={className} style={{ paddingTop: 5 }}>
			<div className="timeline-item-description"><span className="bold medium">{description}</span></div>
			{subDescription && <div className="timeline-item-subdescription"><span>{subDescription}</span></div>}
		</div>
		{children}
	</div>
</li>
