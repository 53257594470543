import React, { useContext, useEffect, useState } from "react"
import LoginForm from "../../../../components/loginForm/LoginForm"
import { UtilityStepEnum } from "../UtilityCustom"
import { Button } from "../../../../components/button/Button"
import { authContext, UserTypes } from "../../../../service/api/authContext"
import { Text } from "../../../../components/text/Text"
import { useTranslation } from "react-i18next"

type ConnectionProps = {
	setFormStep: (value: UtilityStepEnum) => void
}

const Connection = ({ setFormStep }: ConnectionProps) => {
	const { t } = useTranslation("utilityCustom")
	const { logout, user, token, isBillingMonthly } = useContext(authContext)
	const [showContinue, setShowContinue] = useState(false)

	useEffect(() => {
		setShowContinue(typeof token !== "undefined" && (user?.type === UserTypes.SHIPPER || user?.type === UserTypes.ADMIN) && isBillingMonthly)
	}, [token, user, isBillingMonthly])

	return <div className="flex w-full justify-center">
		{!token && <div className="flex self-center w-5/6">
			<LoginForm type="REGISTER_SHIPPER" />
		</div>}
		{token && showContinue && isBillingMonthly && <div className="flex flex-col items-center w-full">
			<div className="flex justify-center w-full pb-5">
				<Text className="flex pr-5">{t("form.connection.content", { user: `${user?.firstname} ${user?.lastname}` })}</Text><div><Button className="flex w-fit" text={t("form.connection.logout")} onClick={logout}/></div>
			</div>
			<div className="flex flex-col">
				<div className="w-52">
					<Button type="primary" text={t("form.connection.continue")} onClick={() => setFormStep(UtilityStepEnum.MERCHANDISE)}/>
				</div>
			</div>
		</div>}
		{token && !showContinue && <>
			<div className="flex flex-col items-center w-full">
				<div className="flex flex-col pb-5">
					<Text className="flex pr-5">{t("form.connection.unauthorized", { user: `${user?.firstname} ${user?.lastname}` })}</Text>
				</div>
				<div className="flex w-3/6 space-between">
					<div className="flex flex-col">
						<Button type="primary" text={t("form.connection.backHome")} to={process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN}/>
					</div>
					<div className="flex flex-col">
						<Button type="primary" text={t("form.connection.logout")} onClick={logout}/>
					</div>
				</div>
			</div>
		</>}
	</div>
}

export default Connection
