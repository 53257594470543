import common from "./common.json"
import offer from "./offer.json"
import heavyCustom from "./heavyCustom.json"
import provision from "./provision.json"
import utilityCustom from "./utilityCustom.json"

export const resources = {
	common,
	offer,
	provision,
	heavyCustom,
	utilityCustom
}

export default resources
