/* eslint-disable no-empty-function, @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext, useState, useEffect } from "react"

// eslint-disable-next-line no-shadow
export enum UserTypes {
	SHIPPER = 1,
	TRANSPORTER = 2,
	ADMIN = 100
}

export type User = {
	firstname: string
	lastname: string
	type: UserTypes
}

type AuthContextType = {
	token: string | null
	isAdmin: boolean
	isBillingMonthly: boolean
	user?: User
	login: (newToken: string, user?: User) => void
	grantAdmin: (isAdminResponse: boolean) => void
	grantAuth: (isAdmin: boolean, isBillingMonthly: boolean) => void
	logout: () => void
}

export const authContext = createContext<AuthContextType>({
	token: "",
	isAdmin: false,
	isBillingMonthly: false,
	user: undefined,
	login: () => {},
	grantAdmin: () => {},
	grantAuth: () => {},
	logout: () => {}
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [token, setToken] = useState<string | null>(localStorage.getItem("token"))
	const [user, setUser] = useState<User | undefined>(undefined)
	const [isAdmin, setIsAdmin] = useState<boolean>(false)
	const [isBillingMonthly, setIsBillingMonthly] = useState<boolean>(false)

	useEffect(() => {
		if(!token && !localStorage.getItem("token")) {
			logout()
		} else if(!token) {
			setToken(localStorage.getItem("token"))
		} else {
			localStorage.setItem("token", token)
		}
	}, [token])

	useEffect(() => {
		if(user?.type === UserTypes.ADMIN) {
			grantAdmin(true)
		}
	}, [user])

	const login = (newToken: string, newUser: AuthContextType["user"]) => {
		setToken(newToken)
		setUser(newUser)
		setIsAdmin(false)
	}

	const logout = () => {
		localStorage.removeItem("token")
		setToken(null)
		setUser(undefined)
		setIsAdmin(false)
	}

	const grantAdmin = (isAdminResponse: boolean) => {
		setIsAdmin(isAdminResponse)
	}

	const grantAuth = (newIsAdmin: boolean, newIsBillingMonthly: boolean) => {
		setIsAdmin(newIsAdmin)
		setIsBillingMonthly(newIsBillingMonthly)
	}

	return (
		<authContext.Provider value={{ token, isAdmin, user, isBillingMonthly, login, logout, grantAdmin, grantAuth }}>
			{children}
		</authContext.Provider>
	)
}

export const useAuth = (): AuthContextType => {
	const context = useContext(authContext)
	if(!context) {
		throw new Error("useAuth must be used within an AuthProvider")
	}
	return context
}
