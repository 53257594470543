import React from "react"


export const formatStr = (str: string) => str.split("\n").
	map((line, index) => {
		if(line === "<br/>") {
			return <br key={index}/>
		}
		return <p key={index} className="text-sm">{line}</p>
	})


