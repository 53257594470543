import React from "react"

type DivierProps = {
	borderClassSize?: string
}

const Divider = ({ borderClassSize = "w-4/5" }: DivierProps) => <div className="relative mt-5 mb-10">
	<div className="absolute h-1 inset-0 flex items-center" aria-hidden="true">
		<div className="w-full flex justify-center">
			<div className={`${borderClassSize} h-1 border-t border-gray-300`}/>
		</div>
	</div>
</div>

export default Divider
