import React, { useContext, useEffect, useState } from "react"
import { authContext } from "../../service/api/authContext"
import Table, { FilterStatusEnum } from "../../components/table/Table"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/Modal/Loading"

type OrderData = {
	"number": string
	"start": string
	"end": string
	"shipper": string
	"carrier": string
	"missions": {
		"id": string
		"start": string
		"end": string
		"vehicule": string
		"code": string
	}[]
}
type OrderResponse = {
	success: boolean,
	orders: OrderData[]
}

const Show = () => {
	const navigate = useNavigate()
	const { token } = useContext(authContext)
	const [loading, setLoading] = useState(false)
	const [ orderData, setOrderData ] = useState<OrderResponse>()
	const [filter, setFilterStatus] = useState({
		carrier: FilterStatusEnum.INACTIVE,
		number: FilterStatusEnum.INACTIVE,
		type: FilterStatusEnum.INACTIVE,
		category: FilterStatusEnum.INACTIVE,
		shipper: FilterStatusEnum.INACTIVE,
		start: FilterStatusEnum.INACTIVE,
		end: FilterStatusEnum.INACTIVE
	})

	const tableHeader = {
		number: {
			label: "N°",
			filter: {
				status: filter.number,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					number: s
				}))
			}
		},
		type: {
			label: "Type",
			filter: {
				status: filter.type,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					type: s
				}))
			}
		},
		category: {
			label: "Catégorie",
			status: filter.category,
			setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
				...prevState,
				category: s
			}))
		},
		status: {
			label: "Status"
			// todo rules for conditional color  ?
		},
		start: {
			label: "Date",
			filter: {
				status: filter.start,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					date: s
				}))
			}
		},
		shipper: {
			label: "Expéditeur",
			filter: {
				status: filter.shipper,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					shipper: s
				}))
			}
		},
		sAmount: {
			label: "HT (E)"
		},
		carrier: {
			label: "Transporteur",
			filter: {
				status: filter.carrier,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					carrier: s
				}))
			}
		},
		cAmount: {
			label: "HT (T)"
		}
	}

	const headerParam = {
		search: {
			fullText: true
		},
		extract: true,
		paging: {
			nbLine: 10
		},
		rowAction: {
			title: "Détails",
			onclick: (e: OrderData) => console.log(e),
			display: (row: OrderData, isClicked: boolean) => {
				if(isClicked) {
					return <div className="border border-c-stone200">
						<div className="grid grid-cols-7 pr-7 pl-7 py-3">
							<div>Du</div>
							<div>Au</div>
							<div>véhicule</div>
							<div>code chauffeur</div>
							<div></div>
						</div>
						{row.missions.map((mission, index) => <>
							<div key={index} className="flex w-full"><div className="w-4/5 h-1 border-t border-c-stone200"/></div>
							<div className="grid grid-cols-7 pr-7 pl-7 py-3">
								<div>{mission.start}</div>
								<div>{mission.end}</div>
								<div>{mission.vehicule}</div>
								<div>{mission.code}</div>
								<div
									onClick={() => {
										navigate(`/mission/${mission.id}/report`)
									}}
									className="bg-yellow400 flex justify-center align-middle rounded-2xl px-1 py-1"
								>Détail
								</div>
							</div>
						</>)}
					</div>
				}
				return <></>
			}
		}
	}

	useEffect(() => {
		setLoading(true)
		const fetchData = async() => {
			try {
				const path = `${process.env.REACT_APP_API_DOMAIN}/api/orders`
				const response = await fetch(path, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				if(!response.ok) {
					navigate("/")
				}
				const orderResponse: OrderResponse = await response.json()
				if(orderResponse && orderResponse.success) {
					setOrderData(orderResponse)
				}
				setLoading(false)
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
				setLoading(false)
			}
		}
		fetchData()
	}, [])

	return <div className="flex flex-col min-h-full px-20 py-10 bg-backGround">
		<span className="self-center text-5xl bold pb-9">Mes commandes</span>
		<Loader open={loading}/>
		<div className="rounded-3xl bg-white w-full p-7">
			{orderData?.orders && <Table<OrderData> data={orderData.orders} header={tableHeader} headerParam={headerParam}/>}
		</div>
	</div>
}

export default Show
