
/**
 * Formate le temps pour passer d'un format HH:mm:ss vers HHhmm
 *
 * @param date Date
 *
 * @returns Une chaîne représentant la date au format dd-mm-yyy.
 */
export const formatDate = (date: Date): string  => {
	// Obtenez le jour, le mois et l'année de la date
	const jour = date.getDate()
	const mois = date.getMonth() + 1 // Les mois commencent à 0, donc ajoutez 1
	const annee = date.getFullYear()

	// Formate les composants dans la chaîne "dd-mm-yyyy"
	const jourFormatte = jour < 10 ? `0${jour}` : jour.toString()
	const moisFormatte = mois < 10 ? `0${mois}` : mois.toString()

	// Retourne la date formatée
	return `${jourFormatte}-${moisFormatte}-${annee}`
}