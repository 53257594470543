import React from "react"
import { CommonStepType } from "../../../pages/litle/Mission/Report/type"
import { MainStep } from "./MainStep"
import { SimpleLine } from "./SimpleLine"
import { ListLine } from "./ListLine"
import { Icon, IconProps } from "../../text/icon/icon"
import { formatMissionOperation } from "../../../helper/format"
import { BufferedImage } from "../../image/BufferedImage"

export type CurrentStepLineType = CommonStepType & { itemIndex: string }

export const CurrentStepLine = (step: CurrentStepLineType) => <MainStep itemText={step.itemIndex} description={step.address} subDescription={step.client} className=" m-media-reset m-b">
	<div className="flex flex-wrap mb-3">
		<div className="basis-1/5">
			{/* Ligne arrivée */}
			{step.arrival && <SimpleLine className="items-end justify-items-start" iconProps={{ type: "location", className: "print:size-4 mr-3" }} description={{ text: "Arrivée" }} subDescription={{ text: step.arrival }}/>}
			{/* Ligne pour les différentes opérations */}
			{step.operations && step.operations.map(
				(operation, key) => <ListLine key={key} type={operation.type} lots={operation.lots}/>
			)}
			{/* Ligne pour la fin d'opération */}
			{step.endOperation && <SimpleLine className="items-end justify-items-start" iconProps={{ type: "operation", className: "print:size-4 mr-3" }} description={{ text: `Fin opération${step.operations && step.operations.length > 0 ? "(s)" : ""}` }} subDescription={{ text: step.endOperation }}/>}
			{/* Ligne pour le départ */}
			{step.departure && <SimpleLine className="items-end justify-items-start" iconProps={{ type: "location", className: "print:size-4 mr-3" }} description={{ text: "Départ" }} subDescription={{ text: step.departure }}/>}
		</div>
		{step.operations && step.operations.map((operation, opIndex) => {
			if(operation.picture) {
				return <div key={opIndex} className="row-block m-l">
					<div className="row v-center">
						<Icon type={formatMissionOperation(operation.type, true) as IconProps["type"]} className="print:size-4"/>
						<span
							className="m-l">{formatMissionOperation(operation.type)} {operation.lotsNumbers && operation.lotsNumbers.length > 0 ? `Lot(s) ${operation.lotsNumbers.join(", ")}` : ""}
						</span>
					</div>
					<BufferedImage src={operation.picture} signature={operation.signature}/>
				</div>
			}
			return <div key={opIndex}></div>
		})}
	</div>
	{/* Lignes pour les incident durant l'étapes */}
	{step.events && step.events.length > 0 && step.events.map((event, evIndex) => <div key={evIndex} className="flex flex-row">
		<SimpleLine
			className="items-center"
			iconProps={{ type: "warning", className:"mr-3 icon-warning", size: "medium" }}
			description={{ text: event.libel }}
			subDescription={{ text: event.comment, className: "incident-background rounded-lg p-2" }}
		/>
	</div>)}
</MainStep>