import React, { useContext, useEffect, useState } from "react"
import { Card } from "../../../components/layout/card/Card"
import { useTranslation } from "react-i18next"
import DatePicker from "../../../components/input/datePicker/DatePicker"
import Location, { GeolocApiTye } from "../../../components/input/location/Location"
import { Button } from "../../../components/button/Button"
import Market, { MarketApiType } from "../Market/Market"
import { authContext } from "../../../service/api/authContext"
import { Text } from "../../../components/text/Text"
import { FamilyCard } from "./content/FamilyCard"
import TimePicker from "../../../components/input/timepicker/TimePicker"
import moment from "moment/moment"
import { CompatibilityCallForBidV1 } from "./CompatibilityCallForBidV1"
import Loader from "../../../components/Modal/Loading"
import { useLocation } from "react-router-dom"
import { FlipIcon } from "../../../components/text/icon/icon"

type VehicleApiType = {
	id: number
	label: string
	description: string
	icon: string
	typologies: { id: number, label: string, description: string, icon: string }[]
} []

type IframeProps = {
	resultId: string,
	halfDay: boolean
}
export type IframeRequest = (props: IframeProps) => void

const Provision = () => {
	const { t } = useTranslation("provision")
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const category = searchParams.get("category")
	const { token } = useContext(authContext)
	const [warningState, setWarningState] = React.useState({
		typology: false,
		date: false,
		time: false,
		location: false
	})
	const changeWarningState = (state: "typology" | "date" | "location" | "time", value = true) => {
		setWarningState((prevState) => ({
			...prevState,
			[state]: value
		}))
	}

	const goToMain = () => {
		if(window.self !== window.top) {
			window.parent.postMessage({
				goToMain: true
			}, process.env.REACT_APP_TRUST_DOMAIN as string)
		} else {
			window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN as string
		}
	}

	const goToContact = () => {
		if(window.self !== window.top) {
			window.parent.postMessage({
				goToContact: true
			}, process.env.REACT_APP_TRUST_DOMAIN as string)
		} else {
			window.location.href = `${process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN}/contactez-nous`
		}
	}

	const [vehicleApi, setVehicleApi] = useState<VehicleApiType>()
	const [marketApi, setMarketApi] = useState<MarketApiType>({ filters: {}, vehicles: {}})
	const [categorySelected, setCategorySelected] = useState<number>(category ? Number(category) : 0)
	const [typologySelected, setTypologySelected] = useState<number>(0)
	const [showMarket, setShowMarket] = useState<boolean>(false)
	const [loaderOpen, setLoaderOpen] = useState(false)
	// Todo optimiser la date en n'en passant qu'une
	const [time, setTime] = useState<moment.Moment | undefined>()
	const changeTime = (newTime: moment.Moment | undefined) => {
		changeWarningState("time", false)
		setTime(newTime)
	}
	const [date, setDate] = useState<Date | undefined>()
	const changeDate = (newDate: Date | undefined) => {
		changeWarningState("date", false)
		setDate(newDate)
	}
	// Todo optimiser la date en n'en passant qu'une
	const [address, setAddress] = useState<GeolocApiTye>({})
	const changeAddress = (newAddress: GeolocApiTye) => {
		changeWarningState("location", false)
		setAddress(newAddress)
	}

	useEffect(() => {
		const fetchData = async() => {
			try {
				const path = `${process.env.REACT_APP_API_DOMAIN}/search/categories/list`
				const response = await fetch(path, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				const data: VehicleApiType = await response.json()
				setVehicleApi(data)
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		const fetchData = async() => {
			setLoaderOpen(true)
			const askedHour = moment(time).format("LTS")
			const askedDate = moment(date).format("YYYY-MM-DD")
			try {
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/search/provision`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${token}`
					},
					body: JSON.stringify({
						address,
						date: `${askedDate} ${askedHour}`,
						typologies: [typologySelected]
					})
				})

				if(response.ok) {
					const data = await response.json()
					if(!data.success) {
						if(data.type === "address") {
							changeWarningState("location", true)
							return
						}
					}
					setMarketApi(data)
					setLoaderOpen(false)
					setShowMarket(true)
					if(!data.vehicles || Object.entries(data.vehicles).length <= 0) {
						window.parent.postMessage({
							callForBirds: true,
							date: askedDate,
							hour: askedHour,
							typology: CompatibilityCallForBidV1[typologySelected as keyof typeof CompatibilityCallForBidV1],
							searchId: data.searchId,
						}, process.env.REACT_APP_TRUST_DOMAIN as string)
					}
				} else {
					setShowMarket(false)
				}
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
			}
		}

		if(showMarket && typologySelected !== -1) {
			fetchData()
		}
	}, [showMarket, typologySelected, date, time, address])

	const confirmCommand = ({ resultId, halfDay }: IframeProps) => {
		const askedDate = moment(date).format("YYYY-MM-DD")
		window.parent.postMessage({
			// eslint-disable-next-line camelcase
			result_id: resultId,
			dates: [askedDate],
			// eslint-disable-next-line camelcase
			half_day: halfDay
		}, process.env.REACT_APP_TRUST_DOMAIN as string)
	}

	const handleClick = () => {
		let cancelValidation = false
		if(!typologySelected) {
			changeWarningState("typology")
			cancelValidation = true
		}
		if(!date) {
			changeWarningState("date")
			cancelValidation = true
		}
		if(!time) {
			changeWarningState("time")
			cancelValidation = true
		}
		if(!address || !address?.address) {
			changeWarningState("location")
			cancelValidation = true
		}
		if(cancelValidation) {
			return
		}
		setShowMarket(true)
	}

	return <div id={"root"} className="h-full bg-third">
		<Loader open={loaderOpen}/>
		<div className="flex flex-col bg-third h-fit items-center justify-center">
			<div className="w-11/12 lg:w-9/12 mt-16">
				<Card type="primary" className="flex p-12 mb-5" style={{ backgroundColor: "white" }}>
					<div className="p-5">
						<div className="flex justify-start">
							<div>
								<Button type="none" text={t("back")} icon={{ side: "left", flip: FlipIcon.horizontal, type: "arrow-left" }}
									onClick={() => goToMain()}/>
							</div>
						</div>
						<div className="flex justify-center mt-2">
							<Text size="large">{t("title")}</Text>
						</div>
					</div>
					<div className="flex flex-col items-center">
						<div className="flex flex-col w-full lg:flex-row space-around mt-10">
							<div className="lg:w-3/12">
								<Text size="big" className="flex justify-center md:block">{t("custom.select.category")}</Text>
								<div className="flex flex-wrap justify-center lg:justify-start -m-2 mt-2">
									{vehicleApi && vehicleApi.map((item, index) => <div key={index} className="p-2">
										<FamilyCard
											icon={item.icon}
											label={item.label}
											description={item.description}
											selected={categorySelected === index}
											vehicleIcon={{ style: { width: "80px" }}}
											setSelected={() => {
												setTypologySelected(-1)
												setCategorySelected(index)
											}}
										/>
									</div>)}
								</div>
							</div>
							<div className="lg:w-8/12">
								{categorySelected >= 0 && <div className="flex flex-col justify-center lg:justify-start">
									<div className="flex flex-col w-fit">
										<Text size="big"
											className="flex justify-center mt-5 lg:mt-0 md:block">{t("custom.select.typology.title")}</Text>
										<div
											className={`${warningState.typology ? "warningForm" : ""} flex flex-wrap items-center justify-center lg:justify-start -m-2 mt-2`}>
											{vehicleApi && vehicleApi[categorySelected].typologies.map((item, key) => <div key={key} className="p-2">
												<FamilyCard icon={item.icon} label={item.label} description={item.description}
													selected={typologySelected === item.id} setSelected={() => {
														changeWarningState("typology", false)
														setTypologySelected(item.id)
													}}/>
											</div>)}
										</div>
									</div>
									{warningState.typology &&
                      <Text className="text-c-warning pl-5 -m-2 mt-2">{t("custom.select.typology.warning")}</Text>}
								</div>}
							</div>
						</div>
						<div className="flex flex-col w-full lg:flex-row space-around mt-10">
							<div className="mb-5 lg:w-5/12">
								<Text size="big">{t("custom.service.date.title")}</Text>
								<div className="mt-2 w-full">
									<div className={`${warningState.date || warningState.time ? "warningForm" : ""} flex w-full bg-neutralLight rounded-full`}>
										<DatePicker type="simple" date={date} setDate={changeDate} placeholder={t("custom.service.date.placeholder.date")}/>
										<TimePicker setTime={changeTime} defaultHour={moment().hour(7)!.minute(0)} time={time} placeholder={t("custom.service.date.placeholder.hour")}/>
									</div>
								</div>
								{(warningState.date || warningState.time) &&
                    <Text className="text-c-warning pl-5">{t("custom.service.date.warning")}</Text>}
							</div>
							<div className="lg:w-6/12">
								<Text size="big">{t("custom.service.location.title")}</Text>
								<div className="mt-2 lg:w-5/6">
									<Location className={warningState.location ? "warningForm" : ""} setAddress={changeAddress}
										placeholder={t("custom.service.location.placeholder")}/>
								</div>
								{warningState.location &&
                    <Text className="text-c-warning pl-5">{t("custom.service.location.warning")}</Text>}
							</div>
						</div>
						<div className="flex flex-col justify-center w-full lg:flex-row lg:w-11/12 mt-5">
							<div className="flex flex-col justify-center lg:w-1/2 mt-10">
								{!showMarket && <div className="flex flex-col w-full justify-center items-center">
									<div className="w-3/5 ml-5 mb-5">
										<Button type="primary" text={t("button.next")} onClick={handleClick}/>
									</div>
								</div>}
								<div className="flex justify-center">
									<Button className="pb-5" type="none" icon={{ side: "left", type: "question-mark" }} text={t("custom.need.help")} onClick={() => {
										goToContact()
									}}/>
								</div>
							</div>
						</div>
					</div>
				</Card>
				<div className="flex w-full items-center">
					{showMarket && marketApi && <Market confirmCommand={confirmCommand} filters={marketApi.filters} vehicles={marketApi.vehicles}/>}
				</div>
			</div>
		</div>
	</div>
}

export default Provision
