import React from "react"
import { Icon } from "../text/icon/icon"
import { Tooltip } from "../toolTip/Tooltip"

type AlertProps = {
	mainTitle: string,
	content: string,
}

const Alert = ({ mainTitle, content }: AlertProps) => <div className="rounded-md bg-yellow-50 px-4 w-fit">
	<div className="flex">
		<div className="flex-shrink-0">
			<Icon type="warning" size="xtr-small" color="primaryDark"/>
		</div>
		<div className="flex w-full ml-3 items-center">
			<h2 className="font-medium text-yellow-800 pr-2">{mainTitle}</h2>
			<Tooltip content={content}>
				<Icon type="question-mark" color="primary" size="xtr-small"/>
			</Tooltip>
		</div>

	</div>
</div>

export default Alert
