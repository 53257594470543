/* eslint-disable no-empty-function, @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext, useState } from "react"

export type Company = {
	id: string
	name: string
	siret: string
}

type CompanyContextType = {
	company: Company
	updateCompany: (newCompany: Company) => void
}

export const companyContext = createContext<CompanyContextType>({
	company: { id: "", name: "", siret: "" },
	updateCompany: () => {}
})

export const CompanyProvider = ({ children }: { children: ReactNode }) => {
	const [company, setCompany] = useState<Company>({
		id: "",
		name: "",
		siret: ""
	})

	const updateCompany = (newCompany: Company) => {
		setCompany(newCompany)
	}

	return (
		<companyContext.Provider value={{ company, updateCompany }}>
			{children}
		</companyContext.Provider>
	)
}

export const useCompany = (): CompanyContextType => {
	const context = useContext(companyContext)
	if(!context) {
		throw new Error("useCompany must be used within an AuthProvider")
	}
	return context
}
