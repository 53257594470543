import React from "react"
import "./button.css"
import { useTranslation } from "react-i18next"
import { Icon, IconProps } from "../text/icon/icon"
import { Link } from "react-router-dom"
import { Text, TextProps } from "../text/Text"

type ButtonProps = {
	type?: "primary" | "secondary" | "none" | "neutral" | "default"
	btnType?: "button" | "submit" | "reset"
	showIcon?: boolean
	icon?: { side?: "left" | "right" } & IconProps
	to?: string
	style?: React.CSSProperties
	text: string
	onClick?: () => any
	className?: string // deprecated
}

export const Button = ({
	type = "default",
	btnType,
	to,
	style,
	text,
	onClick,
	className = "",
	icon = {
		side: "right",
		type: "arrow-left"
	},
	showIcon = true
}: ButtonProps) => {
	const { t } = useTranslation()

	const buttonClasses = {
		"primary": "primary-btn",
		"secondary": "secondary-btn",
		"none": "",
		"neutral": "neutral-btn",
		"default": "button-t2y"
	}

	const iconColors = {
		"primary": "black",
		"secondary": "primary",
		"none": "default",
		"neutral": "primay",
		"default": ""
	}

	const iconSizes = {
		"primary": "small",
		"secondary": "small",
		"none": "xtr-small",
		"neutral": "small",
		"default": ""
	}

	const textSizes = {
		"primary": "big",
		"secondary": "big",
		"none": "small",
		"neutral": "big",
		"default": undefined
	}

	if(!icon.size) {
		icon.size = iconSizes[type] as IconProps["size"]
	}
	if(!icon.color) {
		icon.color = iconColors[type]
	}
	if(!icon.className) {
		icon.className = icon.side === "left" ? "mr-3" : "ml-2"
	}

	const ButtonContent = () => <>
		{type !== "default" && showIcon && icon.side === "left" && <Icon {...icon}/>}
		<Text size={textSizes[type] as TextProps["size"]} className="leading-none">{t(text)}</Text>
		{type !== "default" && showIcon && icon.side === "right" && <Icon {...icon}/>}
	</>

	if(to) {
		return (
			<Link to={to} style={style} className={`w-full ${buttonClasses[type]} flex justify-center items-end rounded-[30px] ${className}`}>
				<ButtonContent />
			</Link>
		)
	}

	return (
		<button onClick={onClick} type={btnType} style={style} className={`w-full ${buttonClasses[type]} flex items-end justify-center rounded-[30px] ${className}`}>
			<ButtonContent />
		</button>
	)
}
