import React, { useContext, useEffect, useState } from "react"
import { authContext } from "../../../../../service/api/authContext"
import Loader from "../../../../../components/Modal/Loading"
import { Card } from "../../../../../components/layout/card/Card"
import AddressStep from "./Content/AddressStep"
import BookingStep from "./Content/BookingStep"
import Resume from "./Content/Resume"
import Next from "./Content/Next"
import Merchandise from "./Content/Merchandise"
import Handling from "./Content/Handling"
import { Text } from "../../../../../components/text/Text"
import { useTranslation } from "react-i18next"
import { Button } from "../../../../../components/button/Button"
import Connection from "./Content/Connection"
import Modal from "../../../../../components/Modal/Modal"
import { Icon } from "../../../../../components/text/icon/icon"

// eslint-disable-next-line no-shadow
export enum UtilityStepEnum {
	ADDRESS = 0,
	BOOKING = 1,
	CONTACT = 2,
	MERCHANDISE = 3,
	HANDLING = 4,
	ADDITIONAL_INFO = 5,
	PAYEMENT = 6
}

export type VehicleApiType = {
	id: number
	label: string
	description: string
	icon: string
	rules: {
		option?: string
		characteristic: { [label: string]: string }
	}
} []

export type ReservationInformationType = {
	searchHistory: number
	slot: {
		id: number | string
		resume: string
	},
	instant: boolean
	option: boolean
	address: {
		first: string
		steps: {
			addr: string | undefined
			isLoading: boolean
		}[]
		last: string
	}
	contacts: {
		first: { name: string, phone: string },
		stepOne?: { name: string, phone: string },
		stepTwo?: { name: string, phone: string },
		last: { name: string, phone: string }
	}
	vehicle: {
		title: string
		option?: string
		picture: string
		smallPicture: string
		characteristic?: { [label: string]: string | { [label: string]: string } }
	}
	date: string
	price: number
	reference?: string
	handler?: string
	floor: number,
	merchandises: {
		label: string
		dimensions: {
			length?: string
			width?: string
			height?: string
		}
		quantity?: number
		weight?: number
		picture?: File[]
	}[]
	additionalInfo: string
}

const UtilityCustomPage = () => {
	// todo utiliser l'id pour les commandes pré faite
	// const { id } = useParams()
	const { token } = useContext(authContext)
	const { t } = useTranslation("utilityCustom")
	const [openLoader, setOpenLoader] = useState(false)
	const [priceLoader, setPriceLoader] = useState(false)
	const [formStep, setFormStep] = useState<number>(UtilityStepEnum.ADDRESS)

	const [modalOpen, setModalOpen] = useState(false)
	const [askFormValid, setaskFormValid] = useState(false)
	const [orderCode, setOrderCode] = useState<string>("")

	const [vehicleApi, setVehicleApi] = useState<VehicleApiType>()
	const [reservationInformation, setReservationInformation] = useState<ReservationInformationType>({
		searchHistory: 0,
		slot: {
			id: 0,
			resume: ""
		},
		instant: false,
		option: false,
		address: {
			first: "",
			steps: [],
			last: ""
		},
		contacts: {
			first: { name: "", phone: "" },
			last: { name: "", phone: "" }
		},
		vehicle: {
			title: "",
			picture: "",
			smallPicture: "",
			characteristic: undefined
		},
		date: "",
		price: 0,
		merchandises: [
			{
				label: "",
				dimensions: {
					length: "",
					width: "",
					height: ""
				},
				picture: []
			}
		],
		handler: "0",
		floor: 0,
		additionalInfo: ""
	})
	useEffect(() => {
		if(!token) {
			return
		}
		setPriceLoader(false)

		const fetchData = async() => {
			try {
				// todo pouvoir resortir une recherche près faite
				const path = `${process.env.REACT_APP_API_DOMAIN}/api/search/Roofmart/typologies`
				const response = await fetch(path, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				const data: VehicleApiType = await response.json()
				setVehicleApi(data)
			} catch (error) {
				// todo Gestion d'erreur fatal
				console.error("Erreur lors de la récupération des données de l'API", error)
			}
		}
		fetchData()
	}, [ token ])

	const orderVehicle = () => {
		// setOpenLoader(true)
		const fetchData = async() => {
			try {
				const path = `${process.env.REACT_APP_API_DOMAIN}/api/search/Roofmart/create-order`
				const response = await fetch(path, {
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${token}`
					},
					method: "POST",
					body: JSON.stringify({
						searchHistory: reservationInformation.searchHistory,
						comment: reservationInformation.additionalInfo
					})
				})
				setOpenLoader(false)
				if(response.ok) {
					const data: { success: boolean, orderCode: string } = await response.json()
					setOrderCode(data.orderCode)
					setaskFormValid(true)
					setModalOpen(true)
				} else {
					setaskFormValid(false)
				}
			} catch (error) {
				console.error("Error lors de la confirmation de la commande")
			}
		}

		fetchData()
	}

	const goToHome = () => {
		if(window.self !== window.top) {
			window.parent.postMessage({ goToHome: true }, process.env.REACT_APP_TRUST_DOMAIN as string)
		} else if(token) {
			window.location.href = `${process.env.REACT_APP_TRUST_DOMAIN}/admin/orders`
		} else {
			window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN as string
		}
	}

	return <div className="bg-third min-h-full">
		<Modal showCloseIcon={false} open={modalOpen} classNames={{ modal: "radius-common" }}>
			<div className="w-full h-fit mt-3 p-2">
				<div className="flex flex-col w-full justify-center items-start">
					<div className="w-full flex flex-row">
						<Icon type={askFormValid ? "check" : "cross"} size="medium" color={askFormValid ? "valid" : "warning"} className="mr-5"/>
						<Text className="mb-5 self-center" size="large">{t(askFormValid ? "form.additionalInfo.popup.valid.title" : "form.additionalInfo.popup.error.title")}</Text>
					</div>
					<Text className="self-center mb-2">{t(askFormValid ? "form.additionalInfo.popup.valid.line.1" : "form.additionalInfo.popup.error.line.1")}</Text>
					<div className="self-center mb-10">
						<Text>{t(askFormValid ? "form.additionalInfo.popup.valid.line.2" : "form.additionalInfo.popup.error.line.2")}</Text>
						{askFormValid && <Text size="medium" className="underline bold">{orderCode}</Text>}
					</div>
					<div className="flex justify-center w-4/6 self-center">
						{askFormValid && <Button btnType="button" type="primary" text={t("form.additionalInfo.popup.valid.button")} onClick={goToHome}/>}
						{!askFormValid && <Button btnType="submit" type="primary" text={t("form.additionalInfo.popup.error.button")} onClick={() => {
							setModalOpen(false)
							orderVehicle()
						}}/>}
					</div>
				</div>
			</div>
		</Modal>
		<Loader open={openLoader}/>
		<div className="flex justify-center">
			<div className="flex w-11/12 md:w-11/12 xl:w-5/6">
				<Card type="primary" className="bg-white mt-10 mr-5">
					{formStep !== UtilityStepEnum.ADDRESS &&
						<Resume reservation={reservationInformation} formStep={formStep} setFormStep={setFormStep} priceLoader={priceLoader}/>}
					{formStep === UtilityStepEnum.ADDRESS && <AddressStep
						token={token}
						loader={{
							active: openLoader,
							setActive: setOpenLoader
						}}
						vehicles={vehicleApi}
						reservation={{
							data: reservationInformation,
							set: setReservationInformation
						}}
						setFormStep={setFormStep}
					/>}
					{formStep === UtilityStepEnum.BOOKING && <BookingStep
						reservation={reservationInformation}
						setFormStep={setFormStep}
					/>}
					{formStep === UtilityStepEnum.CONTACT && <Connection setFormStep={setFormStep} />}
					{formStep === UtilityStepEnum.MERCHANDISE && <Merchandise
						setFormStep={setFormStep}
						input={{
							reference: reservationInformation.reference,
							contacts: reservationInformation.contacts,
							merchandises: reservationInformation.merchandises
						}}
						reservation={{
							data: reservationInformation,
							set: setReservationInformation
						}}
					/>}
					{formStep === UtilityStepEnum.HANDLING && <Handling
						setFormStep={setFormStep}
						handlingInfo={{
							handler: reservationInformation.handler,
							floor: reservationInformation.floor
						}}
						priceLoader={{
							active: priceLoader,
							setActive: setPriceLoader
						}}
						reservationInformation={{
							data: reservationInformation,
							set: setReservationInformation
						}}
					/>}
					{formStep === UtilityStepEnum.ADDITIONAL_INFO && <div className="flex flex-col p-5">
						<Text size="big" className="pb-2 mb-2">{t("form.additionalInfo.title")}</Text>
						<textarea className="border-c-primary border-2 mb-5" value={reservationInformation.additionalInfo} onChange={(event) => {
							setReservationInformation((prevState) => ({
								...prevState,
								additionalInfo: event.target.value
							}))
						}}></textarea>
						<div className="flex pb-5">
							<div className="overflow-auto max-h-24 w-full">{t("form.additionalInfo.disclaimer").split("\n").
								map((line, index) => {
									if(line === "<br/>") {
										return <br key={index}/>
									}
									return <p key={index} className="text-sm font-light text-c-neutral">{line}</p>
								})}</div>
						</div>
						<div className="flex justify-center w-4/6 lg:w-3/6 xl:w-1/4 self-center">
							<Button text={t("form.additionalInfo.continue")} type="primary" btnType="submit" showIcon={false} onClick={orderVehicle}/>
						</div>
					</div>}
					{formStep !== UtilityStepEnum.ADDRESS && <Next formStep={formStep}/>}
				</Card>
			</div>
		</div>
	</div>
}

export default UtilityCustomPage
