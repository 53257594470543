import React from "react"
import { VehicleIconProps, VehicleIcon } from "../../../vehicule/icon/icon"

type TitleVehiculeIconProsp = {
	title?: string
	vehicleIcon: VehicleIconProps
}

export const TitleVehiculeIcon = ({ title, vehicleIcon }: TitleVehiculeIconProsp) => <div className="row p-0">
	<div className="col-9 col-media-9">
		{title && <span className="large bold">{title}</span>}
	</div>
	<div className="col-3 col-media-3">
		<div className="row" style={{ justifyContent: "flex-end" }}>
			{ vehicleIcon && <VehicleIcon {...vehicleIcon}/>}
		</div>
	</div>
</div>