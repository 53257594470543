import Here from "@here/maps-api-for-javascript"

export type RouteType = {
	lat: number,
	lng: number,
	type: number,
	time?: string,
	step?: { [stepNumber: number]: string }
}
export type CoordinatesType = {lat: number, lng: number}

// eslint-disable-next-line no-shadow
export enum STEP_COLOR {
	WAITING = "purple",
	STARTED = "blue",
	FINISHED = "green",
	NEUTRAL = "grey"
}

// eslint-disable-next-line no-shadow
export enum STEP_TYPE {
	WAITING = 1,
	STARTED = 2,
	FINISHED = 3,
	CAR = 4,
	WARNING = 5
}

/**
 * Permet de créer les marqueurs sur la carte
 * @param pointList liste des points avec leurs coordonnées et leurs type
 * @returns Here.map.Marker
 */
export const createMarker = (pointList: RouteType[]) => pointList.reduce((result: Here.map.Marker[], coord) => {
	let svg: string
	if(coord.type === STEP_TYPE.CAR) {
		svg = `<svg fill="#000000" width="40" height="40" viewBox="50 -50 400 400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<g id="Layer_2">
					<g>
						<path d="M401.8,206.5c-1.4-2-3.7-3.2-6.1-3.2h-46.5v-19.8c0-4.1-3.4-7.5-7.5-7.5H173.2c-4.1,0-7.5,3.4-7.5,7.5v121.2 c0,4.1,3.4,7.5,7.5,7.5h21.2c3.9,13.6,16.5,23.7,31.3,23.7s27.4-10,31.3-23.7h83.1c3.9,13.6,16.5,23.7,31.3,23.7 s27.4-10,31.3-23.7h15.5c4.1,0,7.5-3.4,7.5-7.5V243c0-1.6-0.5-3.1-1.4-4.3L401.8,206.5z M410.8,245.2h-33.6v-26.8h14.7 L410.8,245.2z M180.7,191.1h153.5v106.2h-76.5c-2.9-15.1-16.1-26.5-32-26.5s-29.2,11.4-32,26.5h-13V191.1z M225.7,320.9 c-9.7,0-17.6-7.9-17.6-17.6s7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6S235.4,320.9,225.7,320.9z M371.5,320.9 c-9.7,0-17.6-7.9-17.6-17.6s7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6S381.2,320.9,371.5,320.9z M403.5,297.3 c-2.9-15.1-16.1-26.5-32-26.5c-8.6,0-16.5,3.4-22.3,8.9v-61.3h12.9v34.3c0,4.1,3.4,7.5,7.5,7.5h41.3v37.1H403.5z"></path>
						<path d="M93.6,191.1h53.5c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H93.6c-4.1,0-7.5,3.4-7.5,7.5S89.5,191.1,93.6,191.1z"></path>
						<path d="M147.1,203.4h-41.2c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h41.2c4.1,0,7.5-3.4,7.5-7.5S151.3,203.4,147.1,203.4z"></path>
						<path d="M147.1,230.7h-26c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h26c4.1,0,7.5-3.4,7.5-7.5S151.3,230.7,147.1,230.7z"></path>
						<path d="M147.1,258h-9c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h9c4.1,0,7.5-3.4,7.5-7.5S151.3,258,147.1,258z"></path>
					</g>
				</g>
			</svg>`
	} else if(coord.step && coord.type !== -100) {
		const countStep = Object.keys(coord.step).length
		svg = `<svg height="24" xmlns="http://www.w3.org/2000/svg" width="${4.5 * 3 * countStep  + 20}">
			<ellipse stroke="${STEP_COLOR.NEUTRAL}" fill="white" cx="50%" cy="55%" rx="${3 * countStep + 8}pt" ry="10"/>
			<text x="50%" y="55%" font-size="10pt" font-family="Arial" font-weight="bolder" text-anchor="middle" alignment-baseline="middle" >
		`
		svg += Object.keys(coord.step).reduce((str, stepNum: string, index) => {
			let res = ""
			if(index !== 0) {
				res += "-"
			}
			res += '<tspan alignment-baseline="middle"'
			switch(!coord.step ? -1 : Number(coord.step[Number(stepNum)])) {
				case STEP_TYPE.STARTED:
					res += ` fill="${STEP_COLOR.STARTED}"`
					break
				case STEP_TYPE.FINISHED:
					res += ` fill="${STEP_COLOR.FINISHED}"`
					break
				case STEP_TYPE.WAITING:
					res += ` fill="${STEP_COLOR.WAITING}"`
					break
				default:
			}
			return `${str + res}>${stepNum}</tspan>`
		}, "")
		svg += "</text></svg>"

		// switch(coord.type) {
		// 	case 5: // Étape Warning
		// 		svg = `<svg height="24" xmlns="http://www.w3.org/2000/svg" width="24"><circle stroke="orange" fill="orange" cx="50%" cy="55%" r="10"
		// 	height="22" /><text x="12" y="18" font-size="10pt"
		// 	font-family="Arial" font-weight="bold" text-anchor="middle"
		// 		>!</text></svg>`
		// 		break
		// 	case 1: // Étape En attente
		// 		svg = `<svg height="24" xmlns="http://www.w3.org/2000/svg" width="${4.5 * coord.step.length + 20}"><ellipse stroke="${STEP_COLOR.WAITING}" fill="white" cx="50%" cy="55%" rx="${coord.step.length + 8}pt" ry="10" />
		// 		<text x="50%" y="55%" font-size="10pt" font-family="Arial" font-weight="bold" text-anchor="middle" alignment-baseline="middle" >${coord.step}</text>
		// 	</svg>`
		// 		break
		// 	case 2: // Étape Commencé
		// 		svg = `<svg height="24" xmlns="http://www.w3.org/2000/svg" width="${4.5 * coord.step.length + 20}"><ellipse stroke="${STEP_COLOR.STARTED}" fill="white" cx="50%" cy="55%" rx="${coord.step.length + 8}pt" ry="10" />
		// 		<text x="50%" y="55%" font-size="10pt" font-family="Arial" font-weight="bold" text-anchor="middle" alignment-baseline="middle" >${coord.step}</text>
		// 	</svg>`
		// 		break
		// 	case 3: // Étape Fini
		// 		svg = `<svg height="24" xmlns="http://www.w3.org/2000/svg" width="${4.5 * coord.step.length + 20}"><ellipse stroke="${STEP_COLOR.FINISHED}" fill="white" cx="50%" cy="55%" rx="${coord.step.length + 8}pt" ry="10" />
		// 		<text x="50%" y="55%" font-size="10pt" font-family="Arial" font-weight="bold" text-anchor="middle" alignment-baseline="middle" >${coord.step}</text>
		// 	</svg>`
		// 		break
		// 	default:
		// 		return result
		// }
	} else {
		return result
	}
	result.push(new Here.map.Marker(coord, {
		icon: new Here.map.Icon(svg),
		volatility: true
	} as Here.map.Marker.Options))

	return result
}, [])

/**
 * Permet de calculer et générer un tracer sur la carte
 *
 * @param platform platforme here
 * @param map map here
 * @param points liste des points
 * @param reset default false, permet de réinitialier la carte
 */
export const calculateRoute = async(platform: Here.service.Platform, map: Here.Map, points: RouteType[], reset = false) => {
	if(reset) {
		map.removeObjects(map.getObjects())
	}

	const routeResponseHandler = (response: any) => {
		const sections = response.routes[0].sections
		const lineStrings: any[] = []
		sections.forEach((section: any) => {
			// convert Flexible Polyline encoded string to geometry
			lineStrings.push(Here.geo.LineString.fromFlexiblePolyline(section.polyline))
		})
		const multiLineString = new Here.geo.MultiLineString(lineStrings)

		// Create the polyline for the route
		const routePolyline = new Here.map.Polyline(multiLineString, {
			style: {
				lineWidth: 5
			}
		} as any)

		// Add the polyline to the map
		map.addObject(routePolyline)
	}

	// Prépare les points sous le format via de l'url
	const waypoints = points.filter((elem) => elem?.time).map((point) => `${point.lat},${point.lng}`)
	map.addObjects(createMarker(points))

	if(waypoints.length > 0) {
		const response = await manualCallCalculateRoute(waypoints)
		routeResponseHandler(response)
	}
}

export const calculateCenter = (points: CoordinatesType[]): CoordinatesType => {
	if(points.length === 0) {
		return { lat: 43.531755388861825, lng: 5.430983958810115 }
		// Todo feature Renvoyer éventuellement les coordonnées de l'entreprise appartenant a l'utilisateur qui consulte une carte
	} else if(points.length === 1) {
		return points[0]
	}

	let totalLat = 0
	let totalLng = 0

	for(const coordinate of points) {
		totalLat += coordinate.lat
		totalLng += coordinate.lng
	}

	const centralLat = totalLat / points.length
	const centralLng = totalLng / points.length

	return { lat: centralLat, lng: centralLng }
}

export const calculateBounds = (positions: { lat: number; lng: number }[]): H.geo.Rect => {
	const latitudes = positions.map((pos) => pos.lat)
	const longitudes = positions.map((pos) => pos.lng)

	const minLat = Math.min(...latitudes)
	const maxLat = Math.max(...latitudes)
	const minLng = Math.min(...longitudes)
	const maxLng = Math.max(...longitudes)

	return new H.geo.Rect(maxLat, minLng, minLat, maxLng)
}

export const calculateBoundsWithPadding = (positions: { lat: number; lng: number }[], padding: number): H.geo.Rect => {
	const latitudes = positions.map((pos) => pos.lat)
	const longitudes = positions.map((pos) => pos.lng)

	const minLat = Math.min(...latitudes) - padding
	const maxLat = Math.max(...latitudes) + padding
	const minLng = Math.min(...longitudes) - padding
	const maxLng = Math.max(...longitudes) + padding

	return new H.geo.Rect(maxLat, minLng, minLat, maxLng)
}

export const manualCallCalculateRoute = async(points: string[]) => {
	const origin = points[0] // Premier point
	const destination = points[points.length - 1] // Dernier point
	const viaPoints = points.slice(1, -1) // Points intermédiaires (exclut origin et destination)

	const viaParams = viaPoints.map((point) => `&via=${encodeURIComponent(point)}`).join("")

	const apiUrl = `https://router.hereapi.com/v8/routes?xnlp=CL_JSMv3.1.60.0&apikey=P-vntSgzpgTRxTY7Qvhmodfee9s7FHhY4wAlX6tvIJQ&origin=${encodeURIComponent(origin)}${viaParams}&destination=${encodeURIComponent(destination)}&transportMode=car&return=polyline`

	try {
		// Effectuer la requête
		const response = await fetch(apiUrl)

		if(!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`)
		}
		return response.json()
	} catch (e) {
		console.error(e)
	}
}
