import React from "react"
import { Modal as ModalReact } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import loader from "../../assets/commom/loader.gif"

// eslint-disable-next-line no-empty-function,@typescript-eslint/no-empty-function
const Loader = ({ open = false }) => <ModalReact classNames={{ modal: "radius-common" }} showCloseIcon={false} open={open} onClose={() => {}} center>
	<img className="w-96" src={loader} alt="Chargement..."/>
</ModalReact>

export default Loader
