import React, { useState } from "react"
import { Calendar } from "react-date-range"
import { InputDate } from "./InputDate"
import Modal from "../../../Modal/Modal"
import { useTranslation } from "react-i18next"
import { fr } from "date-fns/locale"
import { UseFormRegisterReturn } from "react-hook-form"
import { addYears, eachWeekendOfInterval } from "date-fns"


export type PickerSimpleProps = {
	date: Date | undefined
	error?: boolean
	placeholder?: string
	setDate: (date: Date | undefined) => void
	register?: UseFormRegisterReturn<any>
}

const disabledDates = eachWeekendOfInterval({
	start: new Date(),
	end: addYears(new Date(), 2)
})

const PickerSimple = ({ date, setDate, placeholder, register, error }: PickerSimpleProps) => {
	const { t } = useTranslation("common")
	const [open, setOpen] = useState(false)


	const handleSelect = (selectDate: any) => {
		setDate(selectDate)
		setOpen(false)
	}

	return <div className="w-full">
		<InputDate error={error} onClick={() => setOpen(true)} date={date} placeholder={placeholder} register={register}/>
		<Modal open={open} onClose={() => setOpen(false)}>
			<h1 className="text-center text-3xl">{t("input.date")}</h1>
			<Calendar
				date={date}
				locale={fr}
				onChange={handleSelect}
				color={"var(--primary)"}
				minDate={new Date()}
				disabledDates={disabledDates}
			/>
		</Modal>
	</div>
}

export default PickerSimple
