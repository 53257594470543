import React, { useContext, useRef, useState } from "react"
import { Icon } from "../../text/icon/icon"
import { useTranslation } from "react-i18next"
import SelectMenu, { IconItemProps } from "../select/content/SelectMenu"
import locationLoader from "../../../assets/commom/location-loader.gif"
import { UseFormRegisterReturn } from "react-hook-form"
import { authContext } from "../../../service/api/authContext"


type LocationProps = {
	type?: "primary" | "neutral"
	register?: UseFormRegisterReturn<any>
	placeholder?: string
	input?: {
		value?: string
		className?: string
	}
	setAddress: (address: GeolocApiTye) => void
	className?: string
}

export type GeolocApiTye = {
	type?: string
	showIcon?: boolean
	label?: string
	address?: string
	geometry?: {
		coordinates: number[]
		type: string
	}
	contact?: {
		name: string,
		phone: string,
	}
	properties?: {
		streetNumber: string
		locality: string
		postalCode: string
		streetName: string
	}
}

const Location = ({ setAddress, className, placeholder = "", type = "primary", input, register }: LocationProps) => {
	const { t } = useTranslation()
	const [ showSuggestion, setShowSuggestion ] = useState(false)
	const [ geolocData, setGelocData ] = useState<{ [address: string]: GeolocApiTye }>({})
	const [ locLoader, setLocLoader ] = useState<boolean>(false)
	const [inputValue, setInputValue] = useState<string>(input?.value ? input.value : "")
	const locationRef = useRef<HTMLDivElement | null>(null)
	const [debounceTimeoutId, setDebounceTimeoutId] = useState<NodeJS.Timeout | null>(null)
	const { token } = useContext(authContext)

	const retrieveAddress = (value: string) => {
		if(!value || value.length <= 0) {
			return
		}
		const param = new URLSearchParams({ q: value })

		// Annuler le précédent délai si l'utilisateur tape encore du texte
		if(debounceTimeoutId) {
			clearTimeout(debounceTimeoutId)
		}

		// Définir un nouveau délai pour l'appel à l'API
		setLocLoader(true)
		const newTimeoutId = setTimeout(async() => {
			let response: Response
			if(token) {
				response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/geocode/search?${param.toString()}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
			} else {
				response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/geocode/search?${param.toString()}`, {
					method: "GET"
				})
			}
			if(response.ok) {
				const data = await response.json()
				const geolocDataReponse: { [addr: string]: any } = {}
				data.forEach((item: GeolocApiTye) => {
					if(item.type === "t2yAddress" && item.address) {
						geolocDataReponse[item.label ?? item.address] = { address: item.address, showIcon: true, ...item }
					} else {
						let addressKey = ""
						if(item.properties!.streetNumber) {
							addressKey += item.properties!.streetNumber
						}
						if(item.properties!.streetName) {
							addressKey += ` ${item.properties!.streetName}`
						}
						if(item.properties!.postalCode) {
							addressKey += ` ${item.properties!.postalCode}`
						}
						if(item.properties!.locality) {
							addressKey += ` ${item.properties!.locality}`
						}
						geolocDataReponse[addressKey] = { address: addressKey, showIcon: false, ...item }
					}
				})
				setGelocData(geolocDataReponse)
				setShowSuggestion(true)
			}
			setLocLoader(false)
		}, 1000) // Attendre 1.5 secondes après que l'utilisateur ait arrêté de taper

		setDebounceTimeoutId(newTimeoutId)
	}

	if(type === "neutral") {
		return <div ref={locationRef} className="w-full">
			<input
				className={`focus:outline-none ${input?.className ? input.className : ""}`}
				onChange={(e) => {
					setInputValue(e.target.value)
					retrieveAddress(e.target.value)
				}}
				{...register}
				placeholder={t(placeholder)}
				value={inputValue}
				type="text"
			/>
			<SelectMenu
				setIsOpen={setShowSuggestion}
				inputRef={locationRef}
				isOpen={showSuggestion}
				icon={{ type: "house" }}
				forceShowIcon={true}
				clickOutside={() => {
					if(Object.keys(geolocData).indexOf(inputValue) === -1) {
						setAddress({})
						setInputValue("")
					}
				}}
				values={Object.keys(geolocData)}
				currentValue={[inputValue!]}
				onChange={(value) => {
					setShowSuggestion(false)
					setInputValue(value)
					if(!geolocData[value]) {
						setAddress({})
					}
					setAddress(geolocData[value])
				}}
				canBeNull={false}
			/>
		</div>
	}

	return <div ref={locationRef} className={`${className} location p-1 bg-neutralLight radius-common flex w-full`}>
		<input
			className={`w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal radius-common focus:outline-none ${input?.className}`}
			onChange={(e) => {
				setInputValue(e.target.value)
				retrieveAddress(e.target.value)
			}}
			placeholder={t(placeholder)}
			value={inputValue}
			type="text"
		/>
		{locLoader && <img className="self-center mr-1" style={{ width: 30, height: 25	 }} src={locationLoader} alt="Chargement..."/>}
		{!locLoader && <Icon className="self-center mr-1" type="location" size="medium"/>}
		<SelectMenu
			setIsOpen={setShowSuggestion}
			inputRef={locationRef}
			className="mt-10"
			isOpen={showSuggestion}
			icon={{ type: "house", size: "small" }}
			clickOutside={() => {
				if(Object.keys(geolocData).indexOf(inputValue) === -1) {
					setAddress({})
					setInputValue("")
				}
			}}
			values={Object.keys(geolocData).reduce<IconItemProps[]>((previousValue, currentValue) => {
				previousValue.push({ str: currentValue, showIcon: geolocData[currentValue].showIcon ?? false })
				return previousValue
			}, []).
				sort((a, b) => {
					// Priorité au `showIcon`
					if(a.showIcon !== b.showIcon) {
						return a.showIcon ? -1 : 1 // Priorité à `a` si `a.showIcon` est vrai
					}

					const isANumber = (/^\d/u).test(a.str) // Vérifie si `a.str` commence par un chiffre
					const isBNumber = (/^\d/u).test(b.str) // Vérifie si `b.str` commence par un chiffre

					if(isANumber !== isBNumber) {
						return isANumber ? -1 : 1 // Priorité à `a` si c'est un numéro
					}

					return a.str.localeCompare(b.str)
				})}
			currentValue={[inputValue!]}
			onChange={(value) => {
				setShowSuggestion(false)
				setInputValue(value)
				if(!geolocData[value]) {
					setAddress({})
				}
				setAddress(geolocData[value])
			}}
			canBeNull={false}
		/>
	</div>
}

export default Location
