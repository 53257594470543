/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom"
import Admin from "./pages/litle/Admin/Admin"
import NotFound from "./pages/NotFound"
import Report from "./pages/litle/Mission/Report/Report"
import Offer from "./pages/litle/Offer/Offer"
import { authContext } from "./service/api/authContext"
import { checkAuthentication } from "./service/api/api"
import { checkCompany } from "./service/company/api"
import "./i18n/config"
import Provision from "./pages/litle/Provision/Provision"
import "moment/locale/fr"
import HeavyCustom from "./pages/Custom/Heavy/HeavyCustom"
import UtilityCustom from "./pages/Custom/Utility/UtilityCustom"
import RoofmartCustom from "./pages/Custom/Heavy/Dedicated/Roofmart/RoofmartCustom"
import GraphicDemo from "./pages/Demo/Graphic/GraphicDemo"
import Header from "./components/common/Header"
import Show from "./pages/Order/Show"
import { companyContext } from "./service/company/companyContext"

const AppRouter = (): React.JSX.Element => {
	const [ allowed, setAllowed ] = useState<boolean>(false)
	const [ inIframe, setInIframe ] = useState<boolean>(false)
	const { token, login, grantAuth, isAdmin } = useContext(authContext)
	const { company, updateCompany } = useContext(companyContext)
	useEffect(() => {
		const handleMessage = async (event?: MessageEvent) => {

			// Vérifie l'origine du message pour des raisons de sécurité
			if (event && event.origin === process.env.REACT_APP_TRUST_DOMAIN) {
				setInIframe(true) // Indique que le composant est dans un iframe

				// Vérifie que le token a bien été reçu
				if (typeof event.data?.token === "string") {
					//console.log("Received token:", event.data.token)

					// Vérifie l'authentification avec le token reçu
					try {
						const response = await checkAuthentication(event.data.token)
						//console.log("Authentication response:", response)

						if (response.isAuthenticated) {
							login(event.data.token, response.user) // Mets à jour le contexte d'authentification
							grantAuth(response.isAdmin, response.isBillingMonthly)
							setAllowed(true) // Autorise l'accès
							const companyApi = await checkCompany(event.data.token)
							updateCompany(companyApi)
						} else {
							setAllowed(false)
						}
					} catch (error) {
						console.error("Authentication error:", error)
						setAllowed(false) // Si une erreur survient, interdire l'accès
					}
				} else {
					setAllowed(false) // Si aucun token valide n'est trouvé
				}
			} else {
				// Gère le cas où l'iframe est ouvert directement sans token via postMessage
				const storedToken = localStorage.getItem("token")
				if (storedToken) {
					try {
						const response = await checkAuthentication(storedToken)
						if (response.isAuthenticated) {
							login(storedToken, response.user) // Mets à jour le contexte d'authentification
							grantAuth(response.isAdmin, response.isBillingMonthly)
							setAllowed(true) // Autorise l'accès
							const companyApi = await checkCompany(storedToken)
							updateCompany(companyApi)
						} else {
							localStorage.removeItem("token") // Supprime le token invalide
							setAllowed(false)
						}
					} catch (error) {
						console.error("Authentication error:", error)
						setAllowed(false)
					}
				} else {
					setAllowed(false) // Pas de token dans le localStorage
				}
			}
		}

		if (window.self !== window.top) {
			// Ajoute un listener pour le message
			window.addEventListener("message", handleMessage)

			// Nettoie l'événement lorsqu'il n'est plus nécessaire
			return () => {
				window.removeEventListener("message", handleMessage)
			};
		} else {
			handleMessage()
		}
	}, [])

	document.documentElement.style.setProperty("--primary", "#ffc32d")
	document.documentElement.style.setProperty("--primaryDark", "#d4a124")
	document.documentElement.style.setProperty("--white", "#ffffff")
	document.documentElement.style.setProperty("--whiteRGB", "255, 255, 255")
	document.documentElement.style.setProperty("--black", "#000000")
	document.documentElement.style.setProperty("--third", "#111328")
	document.documentElement.style.setProperty("--second", "#1e2837")
	document.documentElement.style.setProperty("--neutral", "#525f7f")
	document.documentElement.style.setProperty("--neutralLight", "#e7e7f1")
	document.documentElement.style.setProperty("--none", "#ced4da")
	document.documentElement.style.setProperty("--valid", "#1aae6f")
	document.documentElement.style.setProperty("--validLight", "#b0eed3")
	document.documentElement.style.setProperty("--warning", "#F99716")
	document.documentElement.style.setProperty("--warningRGB", "249,151,22")

	document.documentElement.style.setProperty("--backGround", "#E5E5EF")
	document.documentElement.style.setProperty("--baseWhite", "#FFFFFF")
	document.documentElement.style.setProperty("--blue", "#0F1428")
	document.documentElement.style.setProperty("--stone100", "#EAEEF4")
	document.documentElement.style.setProperty("--stone200", "#CFDAE8")
	document.documentElement.style.setProperty("--stone300", "#A5BCD4")
	document.documentElement.style.setProperty("--stone500", "#537BA4")
	document.documentElement.style.setProperty("--stone600", "#406189")
	document.documentElement.style.setProperty("--stone900", "#2B3B4F")
	document.documentElement.style.setProperty("--yellow400", "#FFC32D")

	return <>
		{/* todo optimiser sont emplacement plus tard */}
		{!inIframe && <Header/>}
		<Router>
			<Routes>
				<Route path="/offer" element={<Offer/>}/>
				<Route path="/provision" element={<Provision/>}/>
				<Route path="/utility-custom" element={<UtilityCustom/>}/>
				{process.env.REACT_APP_ROOFMART_ID === company.id && <Route path="/heavy-custom" element={<RoofmartCustom/>}/>}
				{process.env.REACT_APP_ROOFMART_ID === company.id && <Route path="/heavy-custom-estimate" element={<HeavyCustom/>}/>}
				<Route path="/heavy-custom" element={<HeavyCustom/>}/>
				{isAdmin && allowed && <Route path="/admin" element={<Admin/>}/>}
				{isAdmin && allowed && <Route path="/admin/graphicChart" element={<GraphicDemo/>}/>}
				{isAdmin && allowed && <Route path="/admin/orders" element={<Show/>}/>}
				{isAdmin && allowed && <Route path="/admin/order/:orderNumber" element={<Report/>}/>}
				{allowed && <Route path="/mission/:id/report" element={<Report/>}/>}
				<Route path="*" element={<NotFound/>}/>
			</Routes>
		</Router>
	</>
}

const NotFoundRedirect = ({ isAdmin }: { isAdmin: boolean }) => {
	const navigate = useNavigate()

	React.useEffect(() => {
		navigate('/')
	}, [navigate])

	return <>{isAdmin ? "oui ":  "non "}<NotFound/></>
}

export default AppRouter
