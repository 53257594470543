import React, { useContext } from "react"
import { authContext } from "../../service/api/authContext"
import { Icon } from "../text/icon/icon"
import { useTranslation } from "react-i18next"


const Header = () => {
	const { user, token } = useContext(authContext)
	const { t } = useTranslation("common")

	return <header className="bg-gray-800 text-white">
		<div className="top-bar py-2">
			<div className="container mx-auto flex justify-between items-center">
				<div className="flex md:flex-row sm:flex-col contact-info">
					<div><a href="tel:+330123456789" className="flex items-center"><Icon className="mr-2" type="phone" color="white" size="small"/>04 84 80 08 64</a></div>
					<div className="md:ml-5 sm:ml-0">
						<a href="mailto:contact@truck2you.com" className="flex transition-colors duration-300 hover:text-yellow-400">
							<Icon className="mr-2" type="envelope" color="white" size="xtr-small"/> contact@truck2you.com
						</a>
					</div>
				</div>
				<div className="main-header py-4">
					<div className="container mx-auto">
						<div className="logo">
							<a href={process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN}>
								<img
									src="https://res.cloudinary.com/dl2d3weuy/images/v1719326601/Wordpress/LOGO-TRUCK2YOU_LOGO-BLANC-SM/LOGO-TRUCK2YOU_LOGO-BLANC-SM.png?_i=AA"
									alt="Logo Truck2You"
									className="h-12"
								/>
							</a>
						</div>
					</div>
				</div>
				<div className="connect-links flex md:flex-row sm:flex-col items-center">
					{!token && <>
						<a href={`${process.env.REACT_APP_TRUST_DOMAIN}/auth/login`} className="mr-5">{t("header.connection")}</a>
						<div className="social-icons flex">
							<a href="#" className="mr-3 transition-colors duration-300 hover:text-yellow-400">
								<Icon type="facebook" size="xtr-small" color="white"/>
							</a>
							<a href="#" className="transition-colors duration-300 hover:text-yellow-400">
								<Icon type="linkedin" size="xtr-small" color="white"/>
							</a>
						</div>
					</>}
					{token && <div className="flex flex-col items-center">
						<span className="pb-5">{t("header.user", { firstname: user?.firstname, lastname: user?.lastname })}</span>
						<a href={`${process.env.REACT_APP_TRUST_DOMAIN}/auth/login`} className="mr-5">{t("header.account")}</a>
					</div>}
				</div>
			</div>
		</div>
	</header>
}

export default Header
