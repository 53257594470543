import React from "react"
import { UtilityStepEnum } from "../UtilityCustom"
import Divider from "../../../../components/divider/Divier"
import { useTranslation } from "react-i18next"
import { Text } from "../../../../components/text/Text"

type NextProps = {
	formStep: UtilityStepEnum,
}

const Next = ({ formStep }: NextProps) => {
	const { t } = useTranslation("utilityCustom")
	return <>
		{formStep < UtilityStepEnum.CONTACT && <>
			<Divider/>
			<div className="px-5"><Text size="medium">{t("form.connection.title")}</Text></div>
		</>}
		{formStep < UtilityStepEnum.MERCHANDISE && <>
			<Divider/>
			<div className="px-5"><Text size="medium">{t("form.merchandise.title")}</Text></div>
		</>}
		{(formStep < UtilityStepEnum.HANDLING) && <>
			<Divider/>
			<div className="px-5"><Text size="medium">{t("form.handling.title")}</Text></div>
		</>}
		{formStep < UtilityStepEnum.ADDITIONAL_INFO && <>
			<Divider/>
			<div className="px-5"><Text size="medium">{t("form.additionalInfo.title")}</Text></div>
		</>}
	</>
}
export default Next


