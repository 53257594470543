/* eslint-disable */
import React from 'react'
import SimpleRange, {SimpleRangeProps} from "./content/SimpleRange";
import MultipleRange, {MultipleRangeProps} from "./content/MultipleRange";

type RangeProps = ({ type: "simple" } & SimpleRangeProps)
| { type: "multiple" } & MultipleRangeProps

// https://www.npmjs.com/package/react-range?activeTab=readme
const RangeSlider = (props: RangeProps) => {
	switch(props.type) {
		case "simple":
			return <SimpleRange {...props}/>
		case "multiple":
		default:
			return <MultipleRange {...props}/>
	}
}

export default RangeSlider
