import React, { ReactNode, useState } from "react"
import { Button } from "../../../components/button/Button"
import Select from "../../../components/input/select/Select"
import Slider from "../../../components/slider/Slider"
import InputNumber from "../../../components/input/number/Number"
import { NonUndefined, useForm } from "react-hook-form"
import Divider from "../../../components/divider/Divier"
import { FlipIcon, Icon, IconProps } from "../../../components/text/icon/icon"
import Switch from "../../../components/input/switch/Switch"

type ClassColor = {
	[key: string]: string
	primary: string
	primaryDark: string
	white: string
	"white-opacity-80": string
	black: string
	second: string
	third: string
	neutral: string
	neutralLight: string
	none: string
	valid: string
	validLight: string
	warning: string
	warningRGB: string
};

type IconType = {
	type: IconProps["type"]
	size: IconProps["size"]
	color: IconProps["color"]
	rotate: boolean
	flip: keyof typeof FlipIcon
}

const GraphicDemo = () => {
	/* Color */
	const classColor: ClassColor = {
		"primary": "bg-primary",
		"primaryDark": "bg-primaryDark",
		"white": "bg-white",
		"white-opacity-80": "bg-white-opacity-80",
		"black": "bg-black",
		"second": "bg-second",
		"third": "bg-third",
		"neutral": "bg-neutral",
		"neutralLight": "bg-neutralLight",
		"none": "bg-none",
		"valid": "bg-valid",
		"validLight": "bg-validLight",
		"warning": "bg-warning",
		"warningRGB": "bg-warningRGB"
	}

	const selectColor = [ "primary", "primaryDark", "white", "white-opacity-80", "black", "second", "third", "neutral", "neutralLight", "none", "valid", "validLight", "warning", "warningRGB" ]
	const [selectedColor, setSelectedColor] = useState<string[]>(["primary"])

	/* Color end */
	/* Select */
	const selectValue = ["Choix A", "Choix B", "Choix C"]
	const [selectedValue, setSelectedValue] = useState<string[]>([])

	/* Select End */
	/* Input number */
	const {
		register
	} = useForm<{ number: number }>()

	/* Input number End */
	/* Icon select */
	const [selectIcon, setSelectIcon] = useState<IconType>({
		type: "location",
		color: "white",
		size: "medium",
		rotate: false,
		flip: "none"

	})

	const iconSizeValues: NonUndefined<IconProps["size"]>[] = [ "medium", "xtr-small", "small" ]
	const iconTypeValues: NonUndefined<IconProps["type"]>[] = [
		"location",
		"linkedin",
		"facebook",
		"location-rounded",
		"leaf",
		"star",
		"loading",
		"unloading",
		"cross",
		"filter",
		"signature",
		"dropdown",
		"check-line",
		"play",
		"operation",
		"parked",
		"warning",
		"unknown",
		"clock",
		"check",
		"arrow-left",
		"question-mark",
		"calendar",
		"calendar-date-time",
		"dot",
		"upload",
		"useful-width",
		"useful-height",
		"useful-length",
		"phone",
		"useful-weight",
		"envelope",
		"crane-capacity"
	]
	const iconColorType: NonUndefined<IconType["color"]>[] = [ "black", "none", "default", "neutralLight", "primary", "primaryDark", "white", "second", "valid", "validLight", "warning" ]

	const iconHandler = (key: string, value: string | boolean) => {
		setSelectIcon((prev) => ({
			...prev,
			[key]: value
		}))
	}

	/* Icon select End */
	return <div className="bg-third h-full p-5">
		<div className="row w-1/2 pb-5">
			<div className="w-1/6">
				<Button type="secondary" text="Retour" to={"/admin"}/>
			</div>
		</div>
		{/* THEME COLOR */}
		<div>
			<span className="text-c-white pb-2">Select color</span>
			<div className="flex w-full	justify-center">
				<div className="flex flex-row w-2/6">
					<Select selectedValue={selectedColor} setValue={(value) => setSelectedColor([value])} values={selectColor}/>
					<div className={`${classColor[selectedColor[0]] ? classColor[selectedColor[0]] : "bg-primary"} h-full ml-5 p-5`}></div>
				</div>
			</div>
		</div>
		{/* THEME COLOR END */}
		{/* THEME Divier */}
		<div>
			<span className="text-c-white pb-2">Divier</span>
			<Divider/>
		</div>
		<div>
			<span className="text-c-white pb-2">Select simple</span>
			<div className="flex w-full	justify-center">
				<div className="w-1/6">
					<Select selectedValue={selectedValue} setValue={(value) => setSelectedValue([value])} values={selectValue}/>
				</div>
			</div>
		</div>
		{/* THEME Divier END */}
		{/* THEME Bouton */}
		<div>
			<span className="text-c-white pb-2">Bouton</span>
			<div className="flex w-full justify-center">
				<div className="flex flex-col w-1/2 bg-white p-2 flex justify-center items-center">
					<div className="w-2/6 pb-5">
						<span className="pb-2">Default</span>
						<Button btnType="button" text="Test texte"/>
					</div>
					<div className="w-2/6 pb-5">
						<span className="pb-2">primary</span>
						<Button btnType="button" type="primary" text="Test texte"/>
					</div>
					<div className="w-2/6 pb-5">
						<span className="pb-2">neutral</span>
						<Button btnType="button" type="neutral" text="Test texte"/>
					</div>
					<div className="w-2/6 pb-5">
						<span className="pb-2">secondary</span>
						<Button btnType="button" type="secondary" text="Test texte"/>
					</div>
				</div>
			</div>
		</div>
		{/* THEME Bouton END */}
		{/* THEME Slider */}
		<div>
			<span className="text-c-white pb-2">Slider</span>
			<Slider
				leftChildren={(alignment: string): ReactNode => <div className=" w-full bg-warning">
					<span>{alignment}</span></div>}
				rightChildren={(alignment: string): ReactNode => <div className="w-full bg-valid"><span>{alignment}</span>
				</div>}
			/>
		</div>
		{/* THEME Slider END */}
		{/* THEME InputNumber */}
		<div>
			<span className="text-c-white pb-2">Input Number</span>
			<div className="flex w-full justify-center">
				<div className="w-1/6">
					<InputNumber range={{ min: 0, max: 100 }} register={register("number")}/>
				</div>
			</div>
		</div>
		{/*	THEME InputNumber */}
		<div>
			<span className="text-c-white pb-2">Icon</span>
			<div className="flex w-full	justify-center pb-5">
				<div className="w-1/6 mr-5">
					<Select selectedValue={[selectIcon.type as string]} values={iconTypeValues} setValue={(value) => iconHandler("type", value)}/>
				</div>
				<div className="w-1/6 mr-5">
					<Select selectedValue={[selectIcon.size as string]} values={iconSizeValues} setValue={(value) => iconHandler("size", value)}/>
				</div>
				<div className="w-1/6">
					<Select selectedValue={[selectIcon.color as string]} values={iconColorType} setValue={(value) => iconHandler("color", value)}/>
				</div>
			</div>
			<div className="flex w-full	justify-center pb-5">
				<div className="w-1/6 mr-5">
					<span className="text-c-white">flip</span>
					<Select selectedValue={[selectIcon.flip]} values={Object.keys(FlipIcon)} setValue={(value) => iconHandler("flip", value)}/>
				</div>
				<div className="w-1/6">
					<span className="text-c-white">rotation</span>
					<Switch leftText="Oui" rightText="Non" buttonState={selectIcon.rotate as boolean} setButtonState={(value) => iconHandler("rotate", value)}/>
				</div>
			</div>
			<div className="flex justify-center">
				<Icon type={selectIcon.type} color={selectIcon.color} size={selectIcon.size} flip={FlipIcon[selectIcon.flip] as FlipIcon} rotate={selectIcon.rotate}/>
			</div>
		</div>
	</div>
}
export default GraphicDemo
