import { FlipIcon, Icon } from "../../text/icon/icon"
import React from "react"

type PagingProps = {
	currentPage: number
	setCurrentPage: (currentPage: React.SetStateAction<number>) => void
	totalPages: number
}

const Paging = (props: PagingProps) => <div className="flex space-between align-middle pr-7 pl-7 py-3 border border-c-stone200 rounded-b-2xl">
	<button
		onClick={() => props.setCurrentPage((prev) => Math.max(prev - 1, 0))}
		disabled={props.currentPage === 0}
		className={`${props.currentPage > 0 ? "bg-yellow400" : "bg-stone-100"} flex w-1/6 items-center justify-center h-11 min-w-28  py-2 px-3.5 rounded-3xl`}
	>
		<Icon type="arrow-left" className="mr-2" color={props.currentPage > 0 ? "blue" : "stone300"} flip={FlipIcon.horizontal} size="small"/>
		<span className={`text-base font-medium ${props.currentPage > 0 ? "text-c-blue" : "text-c-stone300"}`}>Précédent</span>
	</button>
	<div className="flex">
		{Array.from({ length: props.totalPages }, (_, index) => {
			if(index === props.currentPage - 4 || index === props.currentPage + 4) {
				return <div key={index} className="flex justify-center align-middle text-c-stone500"><span className="self-center pl-1">.</span><span className="self-center pl-1">.</span><span className="self-center pl-1">.</span></div>
			}
			if(index < props.currentPage - 3 || index > props.currentPage + 3) {
				return <div key={index}></div>
			}
			if(index === props.currentPage) {
				return <div key={index} className="bg-yellow400 w-10 h-10 rounded-3xl flex justify-center align-middle">
					<span className="self-center">{index + 1}</span>
				</div>
			}
			return <div
				key={index}
				onClick={() => props.setCurrentPage(index)}
				className="text-c-stone500 w-10 h-10 flex justify-center align-middle cursor-pointer">
				<span className="self-center">{index + 1 }</span>
			</div>
		})}
	</div>
	<button
		onClick={() => props.setCurrentPage((prev) => Math.min(prev + 1, props.totalPages - 1))}
		disabled={props.currentPage === props.totalPages - 1}
		className={`flex w-1/6 items-center justify-center h-11 min-w-28 py-2 px-3.5 ${props.currentPage < (props.totalPages - 1) ? "bg-yellow400" : "bg-stone100"} rounded-3xl`}
	>
		<span className={`text-base font-medium text-c-blue  ${props.currentPage < (props.totalPages - 1) ? "text-c-blue" : "text-c-stone300"}`}>Suivant</span>
		<Icon type="arrow-left" className="ml-2" color={props.currentPage < (props.totalPages - 1) ? "blue" : "stone300"} size="small"/>
	</button>
</div>

export default Paging
