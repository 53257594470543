/* eslint-disable */
import React, {useContext} from "react"
import { useNavigate } from "react-router-dom"
import {Text} from "../../../components/text/Text";
import {Icon} from "../../../components/text/icon/icon";
import {Button} from "../../../components/button/Button";
import {Card} from "../../../components/layout/card/Card";
import {authContext} from "../../../service/api/authContext";

const Admin = () => {
	const { token, isAdmin } = useContext(authContext)
	const navigate = useNavigate()

	return <div className="h-full bg-white p-5">
		<div className="row">
			<Card type="report" className="p-5">
				<Text className="mb-5 self-center" size="large">Voir la charge graphique</Text>
				<Button type="neutral" text="voir" onClick={() => navigate("/admin/graphicChart")}/>
			</Card>
			<span className="p-5"></span>
			<Card type="report" className="p-5">
				<Text className="mb-5 self-center" size="large">Gestionaire de commande</Text>
				<Button type="neutral" text="voir" onClick={() => navigate("/admin/orders")}/>
			</Card>
		</div>
	</div>
}

export default Admin
