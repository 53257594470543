import React, { ReactNode } from "react"
import { Modal as ModalReact, ModalProps as ModalReactProps } from "react-responsive-modal"
import "react-responsive-modal/styles.css"

type ModalProps = {
	children: ReactNode
	onClose?: () => void
	open: boolean
	classNames?: ModalReactProps["classNames"]
	styles?: ModalReactProps["styles"]
	showCloseIcon?: boolean
}

const Modal = ({ children, open, classNames, onClose = () => undefined, showCloseIcon = true }: ModalProps) => <ModalReact showCloseIcon={showCloseIcon} classNames={classNames} open={open} onClose={onClose} center>
	{children}
</ModalReact>

export default Modal
