import React, { CSSProperties } from "react"


export type TextProps = {
	size?: "large" | "big" | "medium" | "base" | "small" | "none" ;
	children: string
	className?: string
	style?: CSSProperties
}
export const Text = ({ size = "base", children, className = "", style }: TextProps) => {
	switch(size) {
		case "large": // size: 30px, height: 36px, weight: 500
			return <h1 style={style} className={`text-3xl font-medium ${className}`}>{children}</h1>
		case "big": // size: 20px, height: 28px, weight:700
			return <span style={style} className={`text-xl font-bold ${className}`}>{children}</span>
		case "medium": // size: 18px, height: 28px, weight: 400
			return <span style={style} className={`text-lg font-normal ${className}`}>{children}</span>
		case "small": // size: 14px, height: 20pxn weight: 300
			return <span style={style} className={`text-sm font-light ${className}`}>{children}</span>
		case "none":
			return <span style={style} className={className}>{children}</span>
		case "base": // size: 16px, height: 24px, weigth: 400
		default:
			return <span style={style} className={`text-base font-normal ${className}`}>{children}</span>
	}
}
