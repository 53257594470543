import React from "react"
import "react-date-range/dist/styles.css" // styles par défaut
import "react-date-range/dist/theme/default.css" // thème par défaut
import { PickerMultiple } from "./content/PickerMultiple"
import PickerSimple, { PickerSimpleProps } from "./content/PickerSimple"

type DateInputProps = {
	type: "multiple"
	from: PickerSimpleProps
	to: PickerSimpleProps
} | ({ type: "simple" } & PickerSimpleProps)

// https://momentjs.com/
// https://hypeserver.github.io/react-date-range/
const DatePicker = (props: DateInputProps) => {
	switch(props.type) {
		case "multiple":
			return <PickerMultiple
				from={props.from}
				to={props.to}
			/>
		case "simple":
		default:
			return <PickerSimple register={props.register} error={props.error} date={props.date} setDate={props.setDate} placeholder={props.placeholder} />
	}
}

export default DatePicker
