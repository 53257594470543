import React from "react"
import { MarketApiType } from "../Market"
import { Card } from "../../../../components/layout/card/Card"
import { QualityMarket, QualityType } from "../../../../components/layout/card/content/QualityMarket"
import { useTranslation } from "react-i18next"
import { Text } from "../../../../components/text/Text"
import { Tooltip } from "../../../../components/toolTip/Tooltip"
import { Button } from "../../../../components/button/Button"
import { IframeRequest } from "../../Provision/Provision"
import { Icon } from "../../../../components/text/icon/icon"
import Rating from "../../../../components/rating/Rating"
import { formatPrice } from "../../../../helper/format"

type MarketItemProps = {
	confirmCommand: IframeRequest
	qualityDescription: QualityType
	vehicle: MarketApiType["vehicles"][0]
	filters: MarketApiType["filters"]
	fullDay: boolean
}
const MarketItem = ({ confirmCommand, qualityDescription, vehicle, fullDay }: MarketItemProps) => {
	const { t } = useTranslation(["provision"])

	const availability = {
		instant: {
			title: t("market.availability.instant.title"),
			toolTip: t("market.availability.instant.tooltip")
		},
		confirmation: {
			title: t("market.availability.confirmation.title"),
			toolTip: t("market.availability.confirmation.tooltip")
		}
	}
	return <div className="w-full">
		<Card
			key={vehicle.vehicleIdV1}
			type="market"
			className="bg-white mb-5"
			vehicleIcon={{
				className: "bg-neutralLight vehicleIcon vehicle-large min-w-72 max-w-72 max-h-64 p-5 radius-common",
				icon: vehicle.icon
			}}
		>
			<div className="flex flex-col mb-5 items-center lg:items-start lg:mb-0 lg:w-3/5 lg:pr-5">
				<div className="max-w-fit mb-2 self-center lg:self-auto">
					<Tooltip content={vehicle.instantBooking ? availability.instant.toolTip : availability.confirmation.toolTip}>
						<div className={`radius-common flex justify-center pb-1 pt-1 pl-2 pr-2 ${vehicle.instantBooking ? "bg-valid" : "bg-warning"}`}>
							<Icon type="question-mark" className="mt-0.5 pr-0.5" size="xtr-small"/>
							<Text size="medium" className="text-c-white">{vehicle.instantBooking ? availability.instant.title : availability.confirmation.title}</Text>
						</div>
					</Tooltip>
				</div>
				<Text className="mb-2" size="large" style={{ fontWeight: "bold" }}>{vehicle.reference}</Text>
				<div className="flex flex-wrap mb-5">
					{vehicle.header && vehicle.header!.length > 0 && Object.entries(vehicle.header!).map(([key, headerItem]) => <Tooltip key={key} content={headerItem.tooltip}>
						<div className="flex items-center pr-5">
							<Icon type={headerItem.icon} size="small"/>
							<Text className="pl-1  leading-none" size="small">{headerItem.content}</Text>
						</div>
					</Tooltip>)}
				</div>
				<div className="mb-5">
					<div className="text-lg font-normal pr-5">
						<Text size="base" className="flex items-center pr-2">{t("market.distance", { distance: vehicle.distance })}</Text>
					</div>
				</div>
				<div className="flex flex-row">
					{vehicle.ecoNote !== undefined && <div className="flex item-center justify-center">
						<Text size="base" className="flex items-center pr-2">{t("market.note.ecological")}</Text>
						<Rating iconSize="small" value={vehicle.ecoNote}/>
					</div>}
				</div>
			</div>
			<div className="flex flex-col justify-between lg:w-2/5 items-center">
				<QualityMarket quality={qualityDescription} selectedQuality={vehicle.qualityOffer}/>
				<div className="flex flex-col items-center justify-center mb-2 mt-3 lg:mt-0 lg:mb-0">
					<Text style={{ fontSize: 35, fontWeight: "bold", lineHeight: "1em" }} size="large">
						{t("market.price", { price: fullDay ? formatPrice(String(vehicle.costDay)) : formatPrice(String(vehicle.costHalfDay)), context: "mark" })}
					</Text>
					<Text size="small" className="text-c-neutral">
						{t("market.reservation.supp", { context: "hour" }) + t("market.price", { price: formatPrice(String(vehicle.costHour)) })}
					</Text>
					<Text size="small" className="text-c-neutral">
						{t("market.reservation.supp", { context: "km" }) + t("market.price", { price: formatPrice(String(vehicle.costKm)) })}
					</Text>
				</div>
				<Button type="primary" icon={{ type: "arrow-left" }} onClick={() => {
					confirmCommand({ resultId: vehicle.searchResultId, halfDay: Number(vehicle.costDay) !== 0 && fullDay })
				}} text={t("market.reservation.confirm")}/>
			</div>
		</Card>
	</div>
}
export default MarketItem
