/* eslint-disable */
import React from "react"

type SwitchProps = {
	buttonState: boolean,
	setButtonState: (state: boolean) => void,
	leftText: string
	rightText: string
	heightSize?: number
}

const Switch = ({ buttonState, setButtonState, heightSize = 9, leftText, rightText }: SwitchProps) => {
	return <div className="w-full radius-common m-auto flex flex-col" onClick={() => setButtonState(!buttonState)}>
		<div className={`shadow bg-white border-c-neutralLight radius-common h-${heightSize} flex relative items-center`}>
			<div className="w-1/2 flex justify-center items-end">
				<span className={`flex justify-center items-end radius-common h-${heightSize - 2} `}>
					{leftText}
				</span>
			</div>
			<div className="w-1/2 flex justify-center items-end">
				<span className={`flex justify-center items-end radius-common h-${heightSize - 2} `}>
					{rightText}
				</span>
			</div>
			<span
				className={`elSwitch bg-primary shadow text-c-black flex justify-center items-end w-1/2 radius-common h-${heightSize - 2} transition-all duration-1000 ease-in-out absolute ${buttonState ? "left-1" : "right-1"}`}>
				{buttonState ? leftText : rightText}
			</span>
		</div>
	</div>
}

export default Switch
