import React from "react"
import { Text } from "../../../../components/text/Text"
import { useTranslation } from "react-i18next"
import { ReservationInformationType, UtilityStepEnum } from "../UtilityCustom"
import { Button } from "../../../../components/button/Button"
import { VehicleIcon } from "../../../../components/vehicule/icon/icon"

export type ConfirmStepProps = {
	setFormStep: (value: UtilityStepEnum) => void
	reservation: ReservationInformationType
}
const BookingStep = ({ setFormStep, reservation }: ConfirmStepProps) => {
	const { t } = useTranslation("utilityCustom")
	const { t: c } = useTranslation("common")

	const handleSubmit = () => {
		// Récupérer le searchHistory depuis la réservation
		const searchHistory = reservation.searchHistory
		const fetchData = async() => {
			try {
				// Effectuer une requête POST pour sauvegarder la commande
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/search/utility/confirm/reservation`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ searchHistory })
				})

				if(!response.ok) {
					throw new Error("Erreur lors de la sauvegarde de la commande")
				}

				setFormStep(UtilityStepEnum.CONTACT)
			} catch (error) {
				console.error("Erreur:", error)
			}
		}
		fetchData()
	}

	return <div>
		<div className="flex justify-center px-5">
			<div className="flex flex-col items-center w-full">
				<Text className="flex self-start" size="large">{t("form.booking.title")}</Text>
				<div className="w-11/12 flex flex-row space-between">
					<div className="flex flex-row">
						<VehicleIcon size={false} className="w-96 mr-5" icon={reservation.vehicle.picture}/>
						<div className="mr-5 px-2 bg-primary"></div>
						<div className="flex flex-col">
							<Text size="big">{`${reservation.vehicle.title} ${reservation.option ? reservation.vehicle.option : ""}`}</Text>
							{reservation.vehicle?.characteristic && <div className="flex flex-col h-full mt-2 pb-5">
								{Object.keys(reservation.vehicle?.characteristic).map((key, index) => {
									if(key === "option") {
										if(reservation.option && typeof reservation.vehicle.characteristic?.option === "object") {
											return <div key={key}>
												{Object.keys(reservation.vehicle.characteristic?.option).map((optKey, optIndex) => <div className="flex mb-5" key={optIndex}>{optKey}: {(reservation.vehicle.characteristic!.option as any)[optKey]}</div>)}
											</div>
										}
										return <></>
									}
									return <div className="flex mb-5" key={index}>{key}: {reservation.vehicle.characteristic![key] as string}</div>
								})}
							</div>}
							<div className="italic text-c-neutral" >* utile</div>
						</div>
					</div>
					<div className="flex flex-col space-between py-5 pr-2">
						<div className="flex justify-center pt-2 px-5 bold">
							<div className="flex w-fit px-5 pt-2 radius-common border-c-primary border-4">
								<Text size="large">{c("currency", { price: reservation.price })}</Text>
								<span className="font-bold">{c("horsTax")}</span>
							</div>
						</div>
						<div className="w-52">
							<Button type="primary" text={t("form.booking.confirm")} onClick={handleSubmit}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}
export default BookingStep
