import { InputDate } from "./InputDate"
import { DateRange } from "react-date-range"
import { fr } from "date-fns/locale"
import React, { useState } from "react"
import Modal from "../../../Modal/Modal"
import { PickerSimpleProps } from "./PickerSimple"

type PickerMultipleProps = {
	from: PickerSimpleProps
	to: PickerSimpleProps
}

export const PickerMultiple = ({ from, to }: PickerMultipleProps) => {
	const [open, setOpen] = useState(false)
	const [state, setState] = useState([
		{
			startDate: undefined,
			endDate: undefined,
			key: "selection"
		}
	])

	const openModal = () => {
		from.setDate(undefined)
		to.setDate(undefined)
		setOpen(true)
	}

	const handleSelect = (ranges: any) => {
		setState([ranges.selection])
		if(!from.date) {
			from.setDate(ranges.selection.startDate.toLocaleDateString())
		} else if(!to.date) {
			to.setDate(ranges.selection.endDate.toLocaleDateString())
			setOpen(false)
		}
	}

	return <div className="flex">
		<InputDate className="mr-5" onClick={() => openModal()} date={from.date} placeholder={from.placeholder}/>
		<InputDate onClick={() => openModal()} date={to.date} placeholder={to.placeholder}/>
		<Modal open={open} onClose={() => setOpen(false)}>
			<DateRange
				locale={fr}
				color={"var(--primary)"}
				rangeColors={[ "var(--primary)" ]}
				ranges={state}
				minDate={new Date()}
				showDateDisplay={false}
				onChange={handleSelect}
			/>
		</Modal>
	</div>
}
