/* eslint-disable */
import React, { useState } from "react"
import "./slotPicker.css"
import { Text } from "../../text/Text"
import { addDays, format, subDays } from "date-fns"
import { FlipIcon, Icon } from "../../text/icon/icon"
import { Button } from "../../button/Button"
import { fr } from "date-fns/locale"
import { useTranslation } from "react-i18next"
import loader from "../../../assets/commom/loader.gif";

export type SlotAvailability = {
	data?: SlotType
	 askChangeDate: (date: Date, openLoader: (a: boolean) => void) => void
	selectedSlot: {
		set: (slot: {
			id: number | string
			range: string // format HH:mm - HH:mm
			fullStrRange: string // format J j m de HH:mm à HH:mm
			resumeRange: string
		}) => void
		value: number | string
	}
}

export type SlotType = {
	[date: string]: {
		id: number | string
		resumeRange: string
		active: boolean
		slotRange: string
	}[]
}

type SlotPickerProps = SlotAvailability

const SlotPicker = ({ data, askChangeDate, selectedSlot }: SlotPickerProps) => {
	const { t } = useTranslation("common")
	const [modalloader, setmodalLoader] = useState(false)
	const [displayAll, setDisplayAll] = useState(false)
	const minDisplay = 20
	const [ slotDateDisplay, setSlotDateDisplay ] = useState({
		prev: subDays(new Date(), 1),
		current: new Date(),
		next: addDays(new Date(), 1)
	})
	const changeDateDisplay = (date: Date) => {
		setSlotDateDisplay({
			prev: subDays(date, 1),
			current: date,
			next: addDays(date, 1)
		})
	}

	const slots = data ? Object.keys(data).map((date) => {
		const dateFns = new Date(date)
		return {
			date: (separator = "-") => format(dateFns, `dd${separator}MM`),
			str: format(dateFns, "eeee", { locale: fr }),
			resumeRange: format(dateFns, "eeee dd MMMM y", { locale: fr }),
			slots: data[date].map((slot) => ({ id: slot.id, active: slot.active, range: slot.slotRange }))
		}
	}) : []

	let maxSlotDisplay = slots.reduce((max, day) => Math.max(max, day.slots.length), 0)
	const formatSlot = (info: { id: number | string, active?: boolean, range: string}[]): {id: number | string, range: string, active?: boolean }[] => {
		const slotsLength = info.length
		let slotsDisplay = info
		let slotToComplete = 0
		if(maxSlotDisplay === 0) {
			maxSlotDisplay = minDisplay
		}

		if(!displayAll) {
			// Réduit la taille des créneaux affiché
			slotsDisplay = info.slice(0, displayAll ? slotsLength : minDisplay)

			if(maxSlotDisplay < minDisplay) {
				slotToComplete = maxSlotDisplay - slotsLength
			} else if(slotsLength < minDisplay) {
				slotToComplete = minDisplay - slotsLength
			}
		} else if(slotsLength < maxSlotDisplay) {
			slotToComplete = maxSlotDisplay - slotsLength
		}

		slotsDisplay = slotsDisplay.concat(Array(Math.max(slotToComplete, 0)).fill({
			id: -1,
			range: "_"
		}))
		return slotsDisplay
	}

	return <div className="flex flex-col items-center">

		<div className="flex flex-row w-fit mb-5">
			<div className="flex justify-center items-center mr-5 icon-container" onClick={() => {
				if (slotDateDisplay.current > new Date()) {
					changeDateDisplay(slotDateDisplay.prev)
					askChangeDate(slotDateDisplay.prev, setmodalLoader)
				}
			}}>
				<Icon
					className={slotDateDisplay.current > new Date() ? "arrow-slot-picker" : ""}
					color={slotDateDisplay.current > new Date() ? "neutral" : "neutralLight"}
					type="arrow-left"
					flip={FlipIcon.horizontal}
				/>
			</div>
			<div className="flex w-fit flex-row relative">
				{modalloader && <div className="flex absolute justify-center items-center top-0 left-0 w-full h-full rounded" style={{
					backgroundColor: "rgba(0, 0, 0, 0.6)",
					transition: "opacity 0.5s ease, visibility 0.5s ease"
				}}>
            <img className="flex justify-center" style={{maxHeight: 80}} src={loader} alt="Chargement..."/>
        </div>}
				{slots.map((day, dayIdx) => <div key={dayIdx} className="flex flex-col mr-2 bg-white">
					<div className="flex flex-col items-center h-9 mb-5 min-w-20">
						<Text size="medium">{day.str}</Text>
						<Text>{day.date()}</Text>
					</div>
					<div className="flex items-center flex-col">
						{/* On réduit la taille du tableau pour avoir 5 créneaux max affiché */}
						{formatSlot(day.slots).map((slot, slotIdx) => <div
							key={slotIdx}
							onClick={() => {
								if(slot.id > 0 || typeof slot.id === "string") {
									selectedSlot.set({
										id: slot.id,
										range: slot.range,
										fullStrRange: `Le ${day.date("/")} arrivée entre ${slot.range}`,
										resumeRange: `${day.resumeRange} entre ${slot.range}`
									})
								}
							}}
							className={`${slot.active !== undefined && !slot.active ? "bg-neutralLight" : ""} ${slot.active ? "slot-container" : ""} ${slot.id === selectedSlot.value ? "bg-primary" : ""} flex w-full justify-center items-center border-2 pt-1 px-2 radius-common h-9 mb-2 min-w-20`}
						>
							<Text size="none" className="text-sm">{slot.range}</Text>
						</div>)}
					</div>
				</div>)}
			</div>
			<div className="flex justify-center items-center ml-5 icon-container" onClick={() => {
				changeDateDisplay(slotDateDisplay.next)
				askChangeDate(slotDateDisplay.next, setmodalLoader)
			}}>
				<Icon className="arrow-slot-picker" type="arrow-left"/>
			</div>
		</div>
		{maxSlotDisplay > minDisplay && !displayAll && <div className="w-fit">
        <Button type="default" showIcon={false} text={t("input.slot.more")} onClick={() => setDisplayAll(true)}/>
		</div>}
	</div>
}

export default SlotPicker
