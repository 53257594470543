import React, { CSSProperties } from "react"
import "./icon.css"
import Icons from "./icons"

export type IconProps = {
	// Todo Optimier ça
	type?: keyof typeof Icons | string,
	size?: "xtr-small" | "small" | "medium"
	color?: "black" | "none" | "default" | "neutralLight" | "primary" | "primaryDark" | "white" | "second" | "valid" | "validLight" | "warning" | string
	rotate?: boolean
	flip?: string
	style?: CSSProperties
	className?: string
}

// eslint-disable-next-line no-shadow
export enum FlipIcon {
	horizontalVertical = "matrix(-1, 0, 0, -1, 0, 0)",
	horizontal = "matrix(-1, 0, 0, 1, 0, 0)",
	vertical = "matrix(1, 0, 0, -1, 0, 0)",
	none = ""
}

// Icon récupéré sur www.svgrepo.com
// eslint-disable-next-line complexity
export const Icon = ({ type, style, color = "default", size = "medium", className = "", flip = FlipIcon.none, rotate = false }: IconProps) => {
	const finalClassName = `icon icon-${size} icon-${color} ${className}`
	let iconTransform = flip
	if(rotate) {
		iconTransform += " rotate(90)"
	}

	const IconComponent = type ? Icons[type as keyof typeof Icons] : null

	if(!IconComponent) {
		return <svg style={style} transform={iconTransform} className={finalClassName}>
			<path
				d="M2 10H4V12H2V10M18 10H20V12H18V10M10 2H12V4H10V2M10 18H12V20H10V18M6 18H8V20H6V18M2 14H4V16H2V14M2 18H4V20H2V18M2 2H4V4H2V2M2 6H4V8H2V6M6 2H8V4H6V2M14 2H16V4H14V2M18 2H20V4H18V2M18 6H20V8H18V6M14 18H16V20H14V18M18 18H20V20H18V18M18 14H20V16H18V14Z"/>
		</svg>
	}

	return <IconComponent className={finalClassName} transform={iconTransform} style={style} />
}
