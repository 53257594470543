/* eslint-disable function-paren-newline, implicit-arrow-linebreak */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { QualityMarketProps, QualityType } from "../../../components/layout/card/content/QualityMarket"
import { IconProps } from "../../../components/text/icon/icon"
import { IframeRequest } from "../Provision/Provision"
import FilterZone, { FilterType } from "./content/FilterZone"
import MarketItem from "./content/MarketItem"
import { Text } from "../../../components/text/Text"

interface Parameter {
	description: string
	rules: {
		[parameter: string]: {
			label: string
			description: string
			type: string
			rules: any
		}
	}
}

type MarketProps = { confirmCommand: IframeRequest } & MarketApiType

export interface MarketApiType {
	filters: {
		[familyId: string]: Parameter
	},
	vehicles: {
		[vehicleId: string]: {
			reference: string
			searchResultId: string
			vehicleIdV1: string
			icon: string
			ecoNote?: number
			header?: {
				icon: IconProps["type"]
				tooltip: string
				content: string
			}[]
			available: boolean
			instantBooking: boolean
			costDay: string
			costHalfDay: string
			costKm: string
			qualityOffer?: QualityMarketProps["selectedQuality"]
			costHour: string
			distance: number
			params: {
				[familyId: string]: {
					[parameterId: string]: any
				}
			}
		}
	}
}

const Market = ({ confirmCommand, filters, vehicles }: MarketProps) => {
	const { t } = useTranslation("provision")
	const [inputFilters, setInputFilters] = useState<FilterType>({})
	const [sortFilters, setSortFilters] = useState<string>("")

	const sortFilterChoice = {
		decreasingPrice: t("market.filter.sort.decreasingPrice"),
		ascendingPrice: t("market.filter.sort.ascendingPrice"),
		location: t("market.filter.sort.location")
	}

	const [fullDay, setFullDay] = useState(true)

	const qualityDescription: QualityType = {
		good: {
			main: t("market.quality.good.main"),
			description: t("market.quality.good.description")
		},
		high: {
			main: t("market.quality.high.main"),
			description: t("market.quality.high.description")
		},
		low: {
			main: t("market.quality.low.main"),
			description: t("market.quality.low.description")
		},
		none: {
			main: t("market.quality.none.main"),
			description: t("market.quality.none.description")
		}
	}

	const [filteredVehicles, setFilteredVehicles] = useState<MarketApiType["vehicles"][0][]>([])
	useEffect(() => {
		const newFilterVehicle = Object.values(vehicles).filter((vehicle) => {
			if(!fullDay && Number(vehicle.costHalfDay) === 0) {
				return false
			}
			return Object.entries(inputFilters).every(([familyId, rules]) => Object.entries(rules).every(([ruleId, value]) => {
				switch(filters[familyId].rules[ruleId].type) {
					case "number":
						if(filters[familyId].rules[ruleId].rules.attr.min === value[0] && filters[familyId].rules[ruleId].rules.attr.max === value[1]) {
							return true
						} else if(!vehicle.params[familyId] || !vehicle.params[familyId][ruleId]) {
							return false
						}
						return value[0] <= vehicle.params[familyId][ruleId] && vehicle.params[familyId][ruleId] <= value[1]
					case "boolean":
						if(!value) {
							return true
						}
						if(!vehicle.params[familyId] || !vehicle.params[familyId][ruleId]) {
							return false
						}
						return vehicle.params[familyId][ruleId] === value
					case "choice":
						if(value.length === 0 || value[0] === "") {
							return true
						}
						if(!vehicle.params[familyId] || !vehicle.params[familyId][ruleId]) {
							return false
						}
						return value.indexOf(vehicle.params[familyId][ruleId]) !== -1
					default:
						return true
				}
			}))
		})
		setFilteredVehicles(newFilterVehicle)
	}, [fullDay, inputFilters])

	return <div className="flex flex-col lg:flex-row w-full">
		<FilterZone sort={{ filter: sortFilters, filterValue: sortFilterChoice, setFilter: setSortFilters }} filters={filters} inputFilters={inputFilters} setInputFilters={setInputFilters} fullDay={fullDay} setFullDay={setFullDay}/>
		<div className="flex flex-col items-center lg:w-9/12">
			{filteredVehicles.length > 0 && filteredVehicles.
				sort((a, b) => {
					if(sortFilters === sortFilterChoice.ascendingPrice) {
						if(fullDay) {
							return Number(a.costDay) - Number(b.costDay)
						}
						return Number(a.costHalfDay) - Number(b.costHalfDay)
					} else if(sortFilters === sortFilterChoice.decreasingPrice) {
						if(fullDay) {
							return Number(b.costDay) - Number(a.costDay)
						}
						return Number(b.costHalfDay) - Number(a.costHalfDay)
					} else if(sortFilters === sortFilterChoice.location) {
						return Number(a.distance) - Number(b.distance)
					}
					return 0
				}).
				map((vehicle) => <MarketItem
					key={vehicle.vehicleIdV1}
					confirmCommand={confirmCommand}
					fullDay={fullDay}
					qualityDescription={qualityDescription}
					vehicle={vehicle}
					filters={filters}
				/>)}
			{filteredVehicles.length === 0 && <div className="">
				<Text className="text-c-white mt-10" size="large">{t("market.noVehicle")}</Text>
			</div>}
		</div>
	</div>
}

export default Market
