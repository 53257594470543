import React from "react"
import { Timeline } from "../../../../../components/timeline/Timeline"
import { MainStep } from "../../../../../components/timeline/element/MainStep"
import { DataRepportApi } from "../type"
import { IncidentStep } from "../../../../../components/timeline/element/IncidentLine"
import { CurrentStepLine } from "../../../../../components/timeline/element/CurrentStepLine"

export type ReportDetailType = { detail: DataRepportApi["missionDetail"] }

export const ReportDetail = ({ detail }: ReportDetailType) => {
	let counter = 0

	return <Timeline>
		{/* Icon play pour démarage mission */}
		{detail.startHour && <MainStep itemIcon={{ type: "play", color: "primary", className: "size-5" }} description={`${detail.startHour} Démarrage Mission`} className="m-b"/>}
		{/* Parcourt des différentes étapes */}
		{detail.steps.length > 0 && detail.steps.map((step, stepIndex) => {
			if(stepIndex === 0) {
				counter = 1
			} else if(step.type === 1) {
				counter++
			}
			return <div key={stepIndex}>
				{step.type === 1 && <CurrentStepLine itemIndex={counter.toString()} {...step} />}
				{step.type === 2 && <IncidentStep description={step.libel} subDescription={step.comment}/>}
			</div>
		})}
		{detail.endHour && <MainStep itemIcon={{ type: "parked", color: "primary", className: "size-5" }} description={`${detail.endHour} Fin de mission`}/>}
	</Timeline>
}
