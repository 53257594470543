import { Company } from "./companyContext"

const apiUrl = process.env.REACT_APP_API_DOMAIN

export type checkAuthenticationResponse = Company

export const checkCompany = async(jwtToken: string): Promise<checkAuthenticationResponse> => {
	try {
		const response = await fetch(`${apiUrl}/api/company`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${jwtToken}`
			},
			method: "GET"
		})

		if(!response.ok) {
			throw new Error("Réponse réseau incorrecte")
		}

		return await response.json()
	} catch (error) {
		console.error("Erreur lors de la vérification de l'authentification:", error)
		return {
			name: "",
			id: "",
			siret: ""
		}
	}
}
