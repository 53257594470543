/* eslint-disable */
import React, { useState } from "react"
import { Icon } from "../../../../components/text/icon/icon"
import { Button } from "../../../../components/button/Button"
import { Text } from "../../../../components/text/Text"
import { ReservationInformationType, UtilityStepEnum } from "../UtilityCustom"
import Divider from "../../../../components/divider/Divier"
import { useTranslation } from "react-i18next"
import { VehicleIcon } from "../../../../components/vehicule/icon/icon"
import loader from "../../../../assets/commom/loader.gif"
import merchandise from "./Merchandise";

type ResumeProps = {
	formStep: UtilityStepEnum
	priceLoader: boolean
	reservation: ReservationInformationType
	setFormStep: (value: UtilityStepEnum) => void
}

const Resume = ({ reservation, formStep, setFormStep, priceLoader }: ResumeProps) => {
	const { t } = useTranslation("utilityCustom")
	const { t: c } = useTranslation("common")

	return <div>
		{formStep >= UtilityStepEnum.BOOKING && <div className="flex space-between">
			<Text size="large" className="pl-5 pt-2">{t("form.resume")}</Text>
		</div>}
		{/* Résumé des étapes / date et heure / véhicule / prix */}
		{formStep >= UtilityStepEnum.BOOKING && <>
			<div className="grid grid-cols-3 gap-3 pl-10">
        <div>
					<div className="flex pt-3 px-5">
						<Icon className="pr-1" type="location-rounded" color="primary"/>
						<div className="flex flex-col">
							<div className="flex flex-col pt-2">
								<Text size="big">{t("form.booking.resume.step.first")}</Text>
								{reservation.address.first}
							</div>
							{reservation.address.steps && reservation.address.steps.length > 0 && reservation.address.steps.map((stepAddress, index) => <div className="flex mb-2" key={index}>
								<div className="flex pt-2">
									<span className="italic">{t("form.booking.resume.step.additional", { step: index + 1 })}</span>&nbsp;{stepAddress.addr!}
								</div>
							</div>)}
							<div className="flex flex-col pt-2">
								<Text size="big">{t("form.booking.resume.step.last")}</Text>
								{reservation.address.last}
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="flex pt-3">
						<Icon className="pr-1" type="calendar-date-time" color="primary"/>
						<div className="flex flex-col pt-1">
							<Text size="big">{t("form.booking.resume.date.title")}</Text>
							<Text>{reservation.date}</Text>
						</div>
					</div>
				</div>
        <div>
					<div className="flex flex-row pb-5">
						<VehicleIcon size={false} className="pl-2 h-14 mr-5" icon={reservation.vehicle.smallPicture}/>
						<div className="flex flex-col pt-5">
							<Text size="big">{t("form.booking.resume.vehicle.title")}</Text>
							<Text>{t("form.booking.resume.vehicle.content", {
								option: reservation.option ? reservation.vehicle.option?.substring(0,1) : "",
								vehicle: reservation.vehicle.title
							})}</Text>
						</div>
					</div>
					{formStep <= UtilityStepEnum.MERCHANDISE && <div className="flex self-end mr-5">
						<div className="w-40">
							<Button type="secondary" className="m-2 text-c-neutral" showIcon={false} text="Modifier"
								onClick={() => {
									setFormStep(UtilityStepEnum.ADDRESS)
								}}
							/>
						</div>
					</div>}
				</div>
			</div>
      <Divider borderClassSize="w-full"/>
		</>}
		{formStep > UtilityStepEnum.MERCHANDISE && <>
			<div className="grid grid-cols-3 gap-3 pl-10">
				<div className="pl-7">
					<Text size="big" className="pl-5 pt-2">{t("form.merchandise.title")}</Text>
					<div className="flex flex-col pl-5 pt-1">
						{reservation.merchandises.map((merchandise, index) => {
							return <div key={index}>
								<span>{t("form.merchandise.resume.article", { label: merchandise.label, weight: merchandise.weight, quantity: merchandise.quantity })}</span>
							</div>
						})}
						<div>
							<span className="italic underline">{t("form.merchandise.resume.total")}</span>&nbsp;&nbsp;<span>{t("form.merchandise.resume.total", {
								context: "value",
								...reservation.merchandises.reduce((previousValue, merc) => {
									return {
										weight: previousValue.weight +(merc.weight! * merc.quantity!),
										quantity: previousValue.quantity + (merc.quantity ? Number(merc.quantity) : 1)
									}
								}, { weight: 0, quantity: 0 })
							}).split("\n").
							map((line, index) => {
								if(line === "<br/>") {
									return <br key={index}/>
								}
								return <p key={index} className="text-sm">{line}</p>
							})}
						</span>
						</div>
					</div>
				</div>
				<div className="flex col-start-3">
					<div className="flex items-end justify-end w-11/12">
						<div className="w-40">
							<Button type="secondary" className="m-2 text-c-neutral" showIcon={false} text="Modifier"
								onClick={() => {
									setFormStep(UtilityStepEnum.MERCHANDISE)
								}}
							/>
						</div>
					</div>
				</div>
			</div>
      <Divider borderClassSize="w-full"/>
		</>}
		{formStep > UtilityStepEnum.HANDLING && <>
      <div className="grid grid-cols-3 gap-3 pl-10">
        <div className="pl-7">
          <Text className="pl-5 pt-2" size="big">{t("form.handling.title")}</Text>
					<div className="px-5">
						<div className="flex space-between w-full">
							<div>
								<Text>{t("form.handling.resume.worker", { count: Number(reservation.handler) })}</Text>&nbsp;
								<Text>{t("form.handling.resume.floor", { count: Number(reservation.floor) })}</Text>
							</div>
						</div>
					</div>
				</div>
        <div></div>
        <div className="flex col-start-3">
					<div className="flex items-end justify-end w-11/12">
						<div className="w-40">
							<Button type="secondary" className="m-2 text-c-neutral" showIcon={false} text="Modifier"
								onClick={() => {
									setFormStep(UtilityStepEnum.HANDLING)
								}}
							/>
						</div>
					</div>
				</div>
      </div>
      <Divider borderClassSize="w-full"/>
    </>}
		{formStep > UtilityStepEnum.BOOKING && <>
			<div className="grid grid-cols-3 gap-3 pl-10">
				<div className="col-start-3">
					<div className="flex items-end justify-end w-11/12">
            <div className="flex w-fit pt-1 radius-common border-c-primary border-4" style={{ width: 150, maxWidth: 160, height: 55, maxHeight: 55 }}>
							{!priceLoader && <div className="flex w-full justify-center">
                <Text size="large" className="pt-2">{c("currency", { price: reservation.price })}</Text>
                <span className="font-bold pt-2">{c("horsTax")}</span>
              </div>}
							{priceLoader && <div className="flex w-full justify-center ">
                <img className="flex justify-center" style={{ maxHeight: 40 }} src={loader} alt="Chargement..."/>
              </div>}
            </div>
					</div>
				</div>
			</div>
		</>}
	</div>
}

export default Resume

