/* eslint-disable */
import React, {useContext} from "react"
import { ReservationInformationType, UtilityStepEnum } from "../UtilityCustom"
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { Text } from "../../../../components/text/Text"
import { Text as InputText } from "../../../../components/input/text/Text"
import { Icon } from "../../../../components/text/icon/icon"
import { Button } from "../../../../components/button/Button"
import { useTranslation } from "react-i18next"
import Alert from "../../../../components/alert/Alert"
import {authContext} from "../../../../service/api/authContext";

type MerchandiseProps = {
	input: {
		merchandises: ReservationInformationType["merchandises"]
		contacts: ReservationInformationType["contacts"]
		reference?: string
	}
	reservation: {
		data: ReservationInformationType,
		set: React.Dispatch<React.SetStateAction<ReservationInformationType>>
	}
	setFormStep: (value: UtilityStepEnum) => void
}

type ContactKey = 'first' | 'last' | 'stepOne' | 'stepTwo';

const Merchandise = ({ input, setFormStep, reservation }: MerchandiseProps) => {
	const { t } = useTranslation("utilityCustom")
	const { token } = useContext(authContext)
	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors }
	} = useForm<MerchandiseProps["input"]>({
		defaultValues: {
			merchandises: input.merchandises,
			reference: input.reference,
			contacts: input.contacts
		}
	})

	const { fields: merchFields, append: appendMerch, remove: removeMerch } = useFieldArray({
		control,
		name: "merchandises",
		rules: { maxLength: 31 }
	})

	const merchandises = watch("merchandises")

	const invalidMerchandises = () => {
		if(!merchandises || merchandises.length <= 0) {
			return false
		}
		return merchandises.every((step, index) => errors.merchandises && errors.merchandises[index])
	}

	const handleRemoveFile = (index: number) => {
		setValue(`merchandises.${index}.picture`, [])
	}

	const onSubmitMerch: SubmitHandler<MerchandiseProps["input"]> = (data) => {
		// Récupérer le searchHistory depuis la réservation
		const searchHistory = reservation.data.searchHistory
		const fetchData = async() => {
			const formData = new FormData()

			Object.entries(data.merchandises).forEach(([index, merchandise]) => {
				if(merchandise.picture && merchandise.picture.length > 0) {
					formData.append(`merchandise${index}`, merchandise.picture[0])
				}
			})
			formData.append("searchHistory", JSON.stringify(searchHistory))
			formData.append("data", JSON.stringify({
				searchHistory,
				...data
			}))

			try {
				// Effectuer une requête POST pour sauvegarder la commande
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/search/utility/update/items`, {
					headers: {
						"Authorization": `Bearer ${token}`
					},
					method: "POST",
					body: formData
				})

				if(!response.ok) {
					throw new Error("Erreur lors de la sauvegarde de la commande")
				}

				setFormStep(UtilityStepEnum.HANDLING)
			} catch (error) {
				console.error("Erreur:", error)
			}
		}
		fetchData()

		reservation.set((prevState) => ({
			...prevState,
			merchandises: data.merchandises || [],
			reference: data.reference,
			contacts: data.contacts
		}))
		// setFormStep(UtilityStepEnum.HANDLING)
	}

	const contactContent: { key: ContactKey }[] = [
		{
			key: "first"
		},
		{
			key: "stepOne"
		},
		{
			key: "stepTwo"
		},
		{
			key: "last"
		}
	]

	return <form onSubmit={handleSubmit(onSubmitMerch)}>
		<div className="flex flex-col mb-5">
			<Text size="big" className="pb-2 mb-2">{t("form.merchandise.title")}</Text>
			<div className="mb-5">
				<Alert
					mainTitle="Attention : Charge utile / Poids total marchandise"
					content="Si le poids total de votre marchandise dépasse la charge utile du véhicule sélectionné, vous devrez passer plusieurs commandes sur ce même véhicule ou choisir un véhicule plus grand pour tout transporter en un seul trajet."
				/>
			</div>
			<div className="flex flex-col ml-5 mr-5">
				<div className="flex justify-center items-center w-2/6 mb-5">
					<Text className="pr-2" size="medium">Référence</Text>
					<InputText register={register("reference", { required: false })}/>
				</div>
				<div className="flex w-full flex-col mb-5">
					<Text className="pr-2 font-bold text-lg mb-4" size="none">Contacts sur les lieux de chargements et déchargements</Text>
					{contactContent.map((content, index) => {
						if(reservation.data.address.steps.length === 0 && (content.key === "stepOne" || content.key === "stepTwo" )) {
							return <div key={index}></div>
						} else if(reservation.data.address.steps.length === 1 && content.key === "stepTwo") {
							return <div key={index}></div>
						}
						return <div key={index}>
							{(errors.contacts?.[content.key]?.name || errors.contacts?.[content.key]?.phone) &&
								<div className="flex w-full">
									<Text className="text-c-warning pl-1">{t("form.merchandise.contact.required")}</Text>
								</div>
							}
							<div className="flex sm:items-start items-center md:flex-row sm:flex-col  w-full mb-2">
								<Text className="pr-2" size="medium">{t(`form.merchandise.contact.${content.key}`)}</Text>
								<div className="flex md:w-3/6 sm:w-full space-around">
									<div className="flex w-full">
										<div className="w-full mr-2">
											<InputText
												className={`${errors.contacts?.[content.key]?.name ? "border-2 border-c-warning" : ""}`}
												placeholder={t("form.merchandise.contact.name", {context: "placeholder"})}
												register={register(`contacts.${content.key}.name`, {required: true})}
											/>
										</div>
										<div className="w-full ml-2">
											<InputText
												className={`${errors.contacts?.[content.key]?.phone ? "border-2 border-c-warning" : ""}`}
												placeholder={t("form.merchandise.contact.phone", {context: "placeholder"})}
												/* eslint-disable-next-line prefer-named-capture-group,require-unicode-regexp */
												register={register(`contacts.${content.key}.phone`, {required: true, pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/i})}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					})}
				</div>
				{invalidMerchandises() &&
            <Text className="text-c-warning pl-5">{t("form.merchandise.detail.error.content")}</Text>}
				{errors.merchandises?.root &&
            <Text className="text-c-warning pl-5">{t("form.merchandise.detail.error.quantity")}</Text>}
				<div className="overflow-auto">
					<div className="w-fit 2xl:w-full bg-neutralLight p-5 radius-common">
						<table className="w-full text-left border-c-third">
							<thead className="bg-third">
								<tr>
									<th scope="col" className="px-2 py-2 rounded-tl-xl min-w-56">
										<Text className="text-c-primary">{t("form.merchandise.detail.table.head.merchandise")}</Text>
									</th>
									<th scope="col" className="px-2 py-2">
										<Text className="text-c-primary">{t("form.merchandise.detail.table.head.dimension")}</Text>
									</th>
									<th scope="col" className="px-2 py-2">
										<Text className="text-c-primary">{t("form.merchandise.detail.table.head.quantity")}</Text>
									</th>
									<th scope="col" className="px-1 py-2 min-w-36">
										<Text className="text-c-primary">{t("form.merchandise.detail.table.head.weight")}</Text>
									</th>
									<th scope="col" className="px-2 py-2">
										<Text className="text-c-primary">{t("form.merchandise.detail.table.head.picture")}</Text>
									</th>
									<th scope="col" className="w-4 rounded-tr-xl">
									</th>
								</tr>
							</thead>
							<tbody className="bg-neutralLight">
								{merchFields.map((merch, index) => <tr key={index}>
									<td scope="col" className="pt-2 pl-1 pr-1">
										<div
											className={`flex p-2 bg-white radius-common ${errors.merchandises && errors.merchandises[index] && errors.merchandises[index]!.label ? "warningForm" : ""}`}>
											<input
												type="text"
												placeholder={t("form.merchandise.detail.table.body.merchandise")}
												className="w-full bg-white focus:outline-none" {...register(`merchandises.${index}.label`, { required: true })}
											/>
										</div>
									</td>
									<td scope="col" className="pt-2 pl-1 pr-1">
										<div className={`flex space-around p-2 bg-white radius-common ${
											errors.merchandises &&
											errors.merchandises[index] &&
											(errors.merchandises[index]!.dimensions?.length ||
												errors.merchandises[index]!.dimensions?.width ||
												errors.merchandises[index]!.dimensions?.height
											) ? "warningForm" : ""}`}>
											<input
												className="focus:outline-none"
												type="number"
												style={{ width: 80 }}
												min={0}
												{...register(`merchandises.${index}.dimensions.length`, { required: true })}
												placeholder={t("form.merchandise.detail.table.body.dimension.length")}
											/>
										x
											<input
												className="pl-1 w-fit focus:outline-none"
												type="number"
												style={{ width: 80 }}
												min={0}
												{...register(`merchandises.${index}.dimensions.width`)}
												placeholder={t("form.merchandise.detail.table.body.dimension.width")}
											/>
										x
											<input
												className="pl-1 w-fit focus:outline-none"
												type="number"
												style={{ width: 80 }}
												min={0}
												{...register(`merchandises.${index}.dimensions.height`)}
												placeholder={t("form.merchandise.detail.table.body.dimension.height")}
											/>
										</div>
									</td>
									<td scope="col" className="pt-2 pl-1 pr-1">
										<div className={`flex p-2 bg-white radius-common ${errors.merchandises && errors.merchandises[index] && errors.merchandises[index]!.quantity ? "warningForm" : ""}`}>
											<input
												className="pl-1 min-w-28 w-full focus:outline-none"
												type="number"
												style={{ width: 74 }}
												min={1}
												{...register(`merchandises.${index}.quantity`, { required: true })}
												placeholder={t("form.merchandise.detail.table.body.quantity")}
											/>
										</div>
									</td>
									<td scope="col" className="pt-2 pl-1 pr-1">
										<div className={`flex p-2 bg-white radius-common ${errors.merchandises && errors.merchandises[index] && errors.merchandises[index]!.weight ? "warningForm" : ""}`}>
											<input
												className="pl-1 focus:outline-none"
												type="number"
												style={{ width: 74 }}
												min={1}
												{...register(`merchandises.${index}.weight`, { required: true })}
												placeholder={t("form.merchandise.detail.table.body.weight")}
											/>
										</div>
									</td>
									<td scope="col" className="pt-2 pl-1 pr-1">
										<div className="flex p-2 bg-white radius-common" style={{ minWidth: 160 }}>
											<Controller
												name={`merchandises.${index}.picture`}
												control={control}
												render={({ field }) => (
													<>
														<input
															type="file"
															id={`file-${index}`}
															hidden
															accept="image/png, image/jpeg"
															onChange={(e) => field.onChange(e.target.files)}
														/>
														<label htmlFor={`file-${index}`} className="flex text-c-neutral w-full">
															{field.value && field.value[0] ? (
																<div className="flex w-full space-around">
																	<span className="truncate" style={{ maxWidth: 100 }}>
																		{field.value[0].name}
																	</span>
																</div>
															) : (
																<div className="flex w-full">
																	<Icon type="upload" size="xtr-small"/>
																	<span className="flex justify-center items-center pl-1">
																		{t("form.merchandise.detail.table.body.picture")}
																	</span>
																</div>
															)}
														</label>
													</>
												)}
											/>
											{merchandises[index] && merchandises[index].picture && merchandises[index]!.picture!.length > 0 && (
												<button type="button" onClick={() => handleRemoveFile(index)}>
													<Icon type="cross" size="xtr-small"/>
												</button>
											)}
										</div>
									</td>
									<td scope="col" className="pt-2 pl-1 pr-1">
										<div className="flex p-2 bg-white radius-common" style={{ minHeight: 34 }}>
											{index > 0 && <button type="button" onClick={() => removeMerch(index)}>
												<Icon type="trash" size="small"/>
											</button>}
											{index <= 0 && <span className="w-6 min-w-6"></span>}
										</div>
									</td>
								</tr>)}
							</tbody>
						</table>
						<div className="flex justify-center mt-5">
							<Button className="w-4/5" type="none" btnType="button" text={t("form.merchandise.detail.table.add")}
								onClick={() => appendMerch({ label: "", dimensions: {}, picture: undefined })}/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="flex justify-center">
			<div className="flex w-1/3">
				<Button text={t("form.handling.continue")} type="primary" btnType="submit"/>
			</div>
		</div>
	</form>
}

export default Merchandise
