import React from "react"
import "./checkbox.css"
import { Icon } from "../../text/icon/icon"

type CheckBoxProps = {
	checked?: boolean,
	setChecked: (value: boolean) => void,
	value?: any
}

const CheckBox = ({ checked = false, setChecked }: CheckBoxProps) => {
	const handleChange = () => {
		setChecked(!checked)
	}

	return (
		<div className="t2y-checkbox inline-flex items-center">
			<label
				className="relative flex cursor-pointer items-center rounded-full p-3"
				htmlFor="checkbox-4"
				data-ripple-dark="true"
			>
				<input
					type="checkbox"
					className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border bg-neutralLight transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity hover:before:opacity-20"
					id="checkbox-4"
					checked={checked}
					onChange={handleChange}
				/>
				<div
					className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
					<Icon type="check-line" size="xtr-small" color="white"/>
				</div>
			</label>
		</div>
	)
}

export default CheckBox
