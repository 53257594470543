/* eslint-disable */
import React from "react"
import {Icon, IconProps} from "../text/icon/icon"

type RatingProps = {
	type?: "leaf" | "star"
	value: number,
	max?: number
	iconSize?: IconProps["size"]
}

const Rating = ({ value, max = 5, type = "leaf", iconSize }: RatingProps) => <div className="flex flex-row">
	{[...Array(max)].map((star, i) => <Icon key={i} type={type as IconProps["type"]} size={iconSize} color={value < i + 1 ? "" : "primary"}/>)}
</div>

export default Rating
