import { Icon, IconProps } from "../../../text/icon/icon"
import { Text } from "../../../text/Text"
import React, { useEffect } from "react"
import "./../select.css"
import { useTranslation } from "react-i18next"

export type IconItemProps = {
	str: string
	showIcon: boolean
	icon?: IconProps["type"]
}

export type SelectMenuProps = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	values: (string | IconItemProps)[]
	currentValue: string[]
	className?: string
	icon?: IconProps
	forceShowIcon?: boolean
	canBeNull?: boolean
	showSelectedItem?: boolean
	clickOutside?: () => void
	onChange: (value: string) => void
	inputRef: React.MutableRefObject<HTMLDivElement | null>
}

type ItemListProps = {
	item: SelectMenuProps["values"][0]
	icon?: IconProps
	forceIcon: boolean
	showSelectedItem?: boolean
	currentValue: string[]
}

const ItemList = ({ item, icon, currentValue, showSelectedItem, forceIcon }: ItemListProps) => {
	if(typeof item !== "string") {
		let typeIcon
		let iconParam
		if(icon) {
			const { type, ...rest } = icon
			typeIcon = type
			iconParam = rest
			if(item.icon) {
				typeIcon = item.icon
			}
		}
		return <div className="flex">
			{(forceIcon || item.showIcon) && icon && <Icon type={typeIcon} {...iconParam}/>}
			<Text className={`block truncate text-c-neutral ${forceIcon || item.showIcon ? "pl-2" : ""}`}>{item.str}</Text>
			{showSelectedItem && currentValue.indexOf(item.str) > -1 && <Icon className="absolute inset-y-0 left-0 self-center pl-1 pr-1" type="check-line" color="primary" size="medium"/>}
		</div>
	}
	return <div className="flex" >
		{forceIcon && icon && <Icon {...icon}/>}
		<Text className="block truncate text-c-neutral">{item}</Text>
		{showSelectedItem && currentValue.indexOf(item) > -1 && <Icon className="absolute inset-y-0 left-0 self-center pl-1 pr-1" type="check-line" color="primary" size="medium"/>}
	</div>
}

const SelectMenu = ({ isOpen, setIsOpen, values, currentValue, onChange, className, canBeNull = true, inputRef, showSelectedItem = false, clickOutside, icon, forceShowIcon = false }: SelectMenuProps) => {
	const { t } = useTranslation("common")

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if(inputRef.current && !inputRef.current.contains(event.target as Node)) {
				if(isOpen && clickOutside) {
					clickOutside()
				}
				setIsOpen(false)
			}
		}

		// Ajouter l'écouteur d'événements quand le composant est monté
		document.addEventListener("mousedown", handleClickOutside)
		// Nettoyer l'écouteur d'événements quand le composant est démonté
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [isOpen])

	return <>
		{isOpen && values.length > 0 &&
			<ul className={`select ${className} absolute z-10 max-h-60 max-w-3xl w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`} tabIndex={-1} role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
				{canBeNull && <li
					id={"listbox-option-empty"}
					onClick={() => onChange("")}
					className="select-item relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
					role="option"
				>
					{currentValue.length === 0 && <Icon className="absolute inset-y-0 left-0 self-center pl-1 pr-1" type="check-line" color="primary" size="medium"/>}
					<Text className="block truncate text-c-neutral">{t("input.select.empty")}</Text>
				</li>}
				{values.map((item, index) => <li key={index}
					className="select-item relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
					id={`listbox-option-${index + 1}`}
					role="option" onClick={() => {
						if(typeof item === "string") {
							onChange(item)
						} else {
							onChange(item.str)
						}
					}}
				>
					<ItemList icon={icon} item={item} showSelectedItem={showSelectedItem} currentValue={currentValue} forceIcon={forceShowIcon}/>
				</li>)}
			</ul>
		}
	</>
}

export default SelectMenu
