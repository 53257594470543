import { IconProps } from "../components/text/icon/icon"


/**
 * Renvoie le code ou le texte en fonction du type de l'opération
 * @param type type de l'opération 1: chargement / 2: déchargement
 * @param code renvoie le code compatible avec les icons
 * @returns renvoie le texte ou l'icone
 */
export const formatMissionOperation = (type: number, code = false): string | IconProps["type"] => {
	switch(type) {
		case 1:
			return code ? "loading" : "Chargement"
		case 2:
			return code ? "unloading" : "Déchargement"
		default:
			return "unknown"
	}
}

export const formatPrice = (price: string): string => price.replace(".", ",")

export const genererCode = () => {
	let result = ""
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
	const charactersLongueur = characters.length
	for(let i = 0; i < 4; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLongueur))
	}
	return result
}
