import React, { CSSProperties, ReactNode } from "react"
import "./card.css"
import { TitleVehiculeIcon } from "./content/TitleVehiculeIcon"
import { VehicleIconProps, VehicleIcon } from "../../vehicule/icon/icon"
import { Tooltip } from "../../toolTip/Tooltip"
import { Icon } from "../../text/icon/icon"

export type CardProps = {
	type?: "primary" | "report" | "market"
	vehicleIcon?: VehicleIconProps

	title?: string
	className?: string
	children: ReactNode
	style?: CSSProperties
}

export const Card = ({ type, title, className, children, vehicleIcon, style }: CardProps) => {
	switch(type) {
		case "primary":
			return <div style={style} className={`card w-full float-left radius-common ${className}`}>
				{(title || vehicleIcon) && <div className="row w-full col-media-12 m-b-large m-media-b">
					{ title && <div className="w-full col-media-12">
						<div className="col-media-12">{vehicleIcon && <TitleVehiculeIcon title={title} vehicleIcon={vehicleIcon}/>}</div>
						<div className="col-media-12">{title && !vehicleIcon && <span className="large bold">{title}</span>}</div>
					</div>}
				</div>}
				<div className="w-full">
					{children}
				</div>
			</div>
		case "market":
			return <article className={`market-card flex flex-col w-full float-left radius-common ${className}`}>
				<div className="flex flex-col lg:flex-row items-center lg:items-start p-5">
					<div className="mb-2 lg:mb-0 lg:pr-5 radius-common">
						<Tooltip content="Photo non contractuelle">
							<Icon className="left-2 top-2 absolute" size="xtr-small" type="question-mark"/>
							<VehicleIcon {...vehicleIcon} size="large"/>
						</Tooltip>
					</div>
					<div className="flex flex-col lg:flex-row justify-between w-full items-stretch">
						{children}
					</div>
				</div>
			</article>
		case "report":
		default:
			return <div style={style} className={className ? `card-report ${className}` : "card-report"}>
				<div className="row col-12 col-media-12 m-b-large m-media-b">
					{ title && <div className="col-12 col-media-12">
						<div className="col-media-12">{ vehicleIcon && <TitleVehiculeIcon title={title} vehicleIcon={vehicleIcon}/>}</div>
						<div className="col-media-12">{ title && !vehicleIcon && <span className="large bold">{title}</span>}</div>
					</div>}
				</div>
				<div className="row col-12">
					<div className="col-12">
						{children}
					</div>
				</div>
			</div>
	}
}
