import React from "react"
import { TableProps } from "../Table"

type FilterProps<T> = {
	headerParam: TableProps<T>["headerParam"]
	textSearch: string
	setTextSearch: (textSearch: string) => void
	extractCSV: () => void
}

const Filter = <T, >(props: FilterProps<T>) => <div className="flex space-between w-full">
	<div className="pb-7">
		{props.headerParam!.search?.fullText && <input
			className="w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal rounded-3xl py-3.5 px-4 focus:outline-none"
			type="text"
			value={props.textSearch}
			placeholder="Rechercher un élément"
			onChange={(e) => props.setTextSearch(e.target.value)}
		/>}
	</div>
	{props.headerParam!.extract && <div className={"flex w-48 items-center justify-center self-center h-8 min-w-3 py-2 px-3.5 bg-yellow400 rounded-3xl"} onClick={props.extractCSV}>Extraire</div>}
</div>

//
export default Filter
